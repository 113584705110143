import * as Types from '@aeppic/types'

// import Document from './document'
import version from '@aeppic/shared/version'

import { Form, FileField, TagMatch, TagMatchOptions } from './form.js'
import { default as Revision, RevisionType } from './revision.js'

import { LockChanges, asReference } from './model.js'

const DEFAULT_MIME_TYPE = 'text/plain'

export interface AddReferenceOptions {
  updateVersionIfAlreadyKnown?: boolean
  allowMultiple?: boolean,
  addToBeginning?: boolean
}

export interface AddLockOptions {
  updateVersionIfAlreadyKnown?: boolean
}

export interface FileSetOptions {
  name?: string
  type?: string
}

const DEFAULT_ADDREFERENCE_OPTIONS: AddReferenceOptions = {
  updateVersionIfAlreadyKnown: false,
  allowMultiple: false,
  addToBeginning: false
}

/**
 * @internal
 */
export interface Options {
  createFileURL: typeof URL.createObjectURL
  insertOrUpdate?: boolean
}

export interface IChangeOperation {
  type: string
}

export interface FieldChangeOperation extends IChangeOperation {
  field: string
}

export interface AtomicIncrement extends FieldChangeOperation {
  type: 'increment'
  delta: number
}

export interface AtomicDecrement extends FieldChangeOperation {
  type: 'decrement'
  delta: number
}

export type ChangeOperation = AtomicIncrement | AtomicDecrement

let PROXY_MODE = false

export function enableProxyMode() {
  PROXY_MODE = true
}

export function disableProxyMode() {
  PROXY_MODE = false
}

export class EditableDocument implements Types.Document {
  private __editVersion = version()
  private __insertOrUpdateMode = false

  private __data: Types.DocumentData
  private __dataProxy: Types.DocumentData
  private __revisions: Revision[]  = []
  private __form: Form
  private __pendingFileUploadFields: Set<string> = null
  private __locks: Types.Reference[] = null
  private __cloneOf: Types.Reference = null
  private __originalCloneOf: Types.Reference = null
  private __hidden: boolean
  private __readonly: boolean
  private __lockChanges: LockChanges[] = [] 
  private __changeOperations: ChangeOperation[] = []
  private __writtenSinceRevision = new Set()

  /** @internal */
  public __base: Types.Document

  /** @internal */
  private __createFileURL: typeof URL.createObjectURL
  
  /** @internal */
  constructor(document: Types.Document, form: Form, options?: Options) {
    if (options) {
      this.__createFileURL = options.createFileURL
      this.__insertOrUpdateMode = options.insertOrUpdate === true
    }

    this.updateBase(document, form)
    // Object.preventExtensions(this) // Cannot do this. Vue reactivity would be impacted
  }

  public cloneAsDocument(): Types.Document {
    return JSON.parse(this.toJSONString())
  }

  public toString() {
    return this.toJSONString()
  }

  public toJSONString() {
    return JSON.stringify(this._toDocument())
  }

  private _toDocument() {
    const doc: Types.Document = {
      id: this.id,
      p: this.p,
      f: this.f,
      previous: this.__base.previous,
      v: this.__editVersion,
      data: this.__data,
      locks: this.__locks,
      modified: this.__base.modified,
      created: this.__base.created,
      t: this.__base.t,
      ct: this.__base.ct,
      a: this.a,
      a_depth: this.a_depth,
      a_forms: this.a_forms,
      inheritedLocks: this.inheritedLocks,
    }

    if (this.__readonly) {
      doc.readonly = true
    }

    if (this.__hidden) {
      doc.hidden = true
    }

    return doc
  }

  public static isEditableDocument(document: any): document is EditableDocument {
    return (document && document.constructor === EditableDocument)
  }

  /**
   * Whenever the user commits an editable document a revision is
   * created. 
   */
  get editVersion() {return this.__editVersion}

  get revisions() {return this.__revisions.slice(0)}
  get hasRevisions() {return this.__revisions.length > 0}
  get revisionCount() {return this.__revisions.length}

  get hidden() {return this.__hidden}
  set hidden(value: boolean) {
    this.__hidden = value
  }

  // Readonly is a soft readonly. It protects from accidental changes
  // inside controllers etc, but it is not a lock. When document changes
  // are sent to the server the server will accept the write even if the
  // document is readonly. Readonly is a client side protection only.
  //
  // To actually prevent unauthorized changes use locks.
  get readonly() {return this.__readonly}
  set readonly(value: boolean) {
    this.__readonly = value
  }

  get insertOrUpdateMode() { return this.__insertOrUpdateMode }

  /**
   * @internal
   * 
   * Revisions document changes made to the document
   * they provide no security or confidentiality 
   */
  addRevision(revision: Revision) {
    this.__revisions.push(revision)
    this.__editVersion = version()
    this.__writtenSinceRevision.clear()
  }

  asReference() {
    return asReference(this)
  }

  /**
   * @internal
   * 
   * This is called for example when the base of the document is updated
   * and no more changes exist
  */
  clearRevisions() {
    this.__revisions = []
    this.__editVersion = version()
  }

  get lastRevisionType(): RevisionType {
    if (this.__revisions.length === 0) {
      return 'user'
    } else {
      return this.__revisions[this.__revisions.length - 1].type
    }
  }

  /** @internal */
  updateBase(document: Types.Document, form: Form, dataToUse?: any) {
    this.__base = document
    this.__form = form

    this.__cloneOf = cloneReference(document.cloneOf)
    this.__originalCloneOf = cloneReference(document.originalCloneOf)

    this.__locks = cloneReferences(document.locks)
    this.__data = form.cloneDocumentData(dataToUse || document.data)
    this.__dataProxy = PROXY_MODE ? this._buildProxy(this.__data) : null

    this.__hidden = document.hidden === true
    this.__readonly = document.readonly === true

    this.__form.applyOptionalFieldRules(this.__data)
    this.__form.updateCalculatedFields(this.__data)
    this.__form.applyFormatting(this.__data)
    this.__form.applyOptionalFieldRules(this.__data)
  }

  private _buildProxy(data: any) {
    // Build a proxy that will intercept all field accesses and writes.
    // We will pass through all fields that are part of the form and
    // all properties that start with '__v_' (for vue compatibility)

    const handler = {
      set: (target: any, property: string, value: any) => {
        if (!property) {
          return
        }

        if (isDynamicVueProperty(property) || isSymbol(property)) {
          target[property] = value
          return
        }

        if (!this.__form.hasField(property)) {
          console.error(`Trying to write to unknown field ${property} on document ${this.id}@${this.v}`)
          throw new Error(`Unknown field ${property}`)
        }

        this._throwIfReadonly()

        // Check type
        if (!this.__form.validateFieldValue(property, value)) {
          console.error(`Invalid value for field ${property} on document ${this.id}@${this.v}`)
          throw new Error(`Invalid value for field ${property}`)
        }

        this.__writtenSinceRevision.add(property)
        target[property] = value;

        // Indicate success
        return true
      },
      get: (target: any, property: string) => {
        if (!property) {
          return 
        }
        
        if (isDynamicVueProperty(property) || isSymbol(property)) {
          return this.__base[property]
        }

        if (!this.__form.hasField(property)) {
          console.warn(`Accessing unknown field ${property} on document ${this.id}@${this.v}`)
        }

        return target[property]
      }
    }

    return new Proxy(data, handler);
  }

  get form() {
    return this.__form
  }

  get id() {return this.__base.id}
  get v() {return this.__base.v}
  get previous() {return this.__base.previous}
  get p() {return this.__base.p}
  get f() {return this.__base.f}
  get cloneOf() {return this.__cloneOf}  
  get originalCloneOf() {return this.__originalCloneOf}  
  get meta() {return this.__base.meta }
  
  /**
   * Previous document ONLY includes the changes from the previous saved version to the `base` of this `EditableDocument`. It
   * does NOT include currently made changes to this instance of `EditableDocument`
   */
  
  get data() {
    return this.__dataProxy ?? this.__data
  }

  set data(data: any) {
    this.setData(data, { cloneFromDefault: true })
  }

  private _throwIfReadonly() {
    if (this.__readonly) {
      throw new Error('Cannot change document when readonly')
    }
  }

  isUndefinedField(fieldName: string) {
    return this.__form.isUndefinedField(this, fieldName)
  }

  getUndefinedFields(sectionIndex?: number) {
    return this.__form.getUndefinedFields(this, sectionIndex)
  }

  getUndefinedSections() {
    return this.__form.getUndefinedSections(this)
  }

  isTaggedField(fieldName: string, matcher: TagMatch[], options?: TagMatchOptions) {
    return this.__form.isTaggedField(fieldName, matcher, options)
  }

  getTaggedFields(matcher: TagMatch[], sectionIndex?: number): string[]
  getTaggedFields(matcher: TagMatch[], options?: TagMatchOptions): string[]
  getTaggedFields(matcher: TagMatch[], sectionIndexOrOptions?: number|TagMatchOptions, options?: TagMatchOptions): string[]
  getTaggedFields(...args: any[]): string[] {
    return this.__form.getTaggedFields.call(this.__form, ...args) 
  }

  getTaggedSections(matcher: TagMatch[], options?: TagMatchOptions) {
    return this.__form.getTaggedSections(matcher, options)
  }

  setSectionToUndefined(sectionIndex: number) {
    for (const field of this.form.enumerateAllFieldsInSection(sectionIndex)) {
      const isUndefinedField = this.data[field.name] === undefined

      if (!isUndefinedField) {
        this.data[field.name] = undefined
      }
    }
  }

  resetFieldToDefaults(fieldName: string) {
    const field = this.form.getField(fieldName)

    if (!field) {
      throw new Error(`Field ${fieldName} does not exist`)
    }

    this.data[fieldName] = this.form.cloneFieldValue(undefined, fieldName, true)
  }

  resetSectionToDefaults(sectionIndex: number) {
    for (const field of this.form.enumerateAllFieldsInSection(sectionIndex)) {
      const isUndefinedField = this.data[field.name] === undefined

      if (isUndefinedField) {
        this.data[field.name] = this.form.cloneFieldValue(undefined, field.name, true)
      }
    }
  }

  setField(fieldName: string, newValue: any, { ignoreReadonly = false }: { ignoreReadonly?: boolean } = {}) {
    if (!ignoreReadonly) {
      this._throwIfReadonly()
    }

    const field = this.__form.getField(fieldName)

    if (!field) {
      throw new Error(`Field ${fieldName} does not exist`)
    }

    if (!this.__form.validateFieldValue(fieldName, newValue)) {
      throw new Error(`Invalid value for field ${fieldName}`)
    }

    this.__writtenSinceRevision.add(fieldName)
    this.__data[fieldName] = this.__form.cloneFieldValue(newValue, fieldName, true)
  }

  setData(newData: any, options: {cloneFromDefault?: boolean, excludeFileFields?: boolean, onlyMatchingFields?: boolean, ignoreReadonly?: boolean} = {}) {
    if (options.ignoreReadonly !== true) {
      this._throwIfReadonly()
    }

    const cloneFromDefault = options.cloneFromDefault
    const excludeFileFields = options.excludeFileFields
    const onlyMatchingFields = options.onlyMatchingFields

    const data = this.data

    for (const fieldInfo of this.__form.fields) {
      if ((fieldInfo.subType === 'file' || fieldInfo.subType === 'image') && excludeFileFields) {
        continue
      }

      if (onlyMatchingFields) {
        if (fieldInfo.name in newData) {
          continue
        }
      }

      const fieldName = fieldInfo.name
      let value = newData[fieldName]

      if (value && Form.isRefField(fieldInfo)) {
        if (fieldInfo.cardinality) {
          value = value.map(ref => fixOldStyleReference(ref))
        } else {
          value = fixOldStyleReference(value)
        }
      }

      if (typeof value === 'undefined') {
        if (this.form.allowsUndefinedFields) {
          if (cloneFromDefault) {
            throw new Error('Cannot set a field to `undefined` when the form allows undefined fields but cloneFromDefault is true. The behavior is duplicitous in this case. Should it clone from default or assign undefined')
          }
          this.__writtenSinceRevision.add(fieldName)
          this.__data[fieldName] = undefined
        } else {
          if (!cloneFromDefault) {
            throw new Error(`New data is missing field ${fieldName} which is not allowed by the form, document: ${this.id}@${this.v}, form: ${this.__form.id}@${this.__form.v}`)
          }
          this.__writtenSinceRevision.add(fieldName)
          this.__data[fieldName] = this.__form.cloneFieldValue(value, fieldName, cloneFromDefault)
        }
      } else {
        if (!this.__form.validateFieldValue(fieldName, value)) {
          throw new Error(`Invalid value for field ${fieldName} in document ${this.id}@${this.v} form ${this.__form.id}@${this.__form.v}`)
        }

        this.__writtenSinceRevision.add(fieldName)
        this.__data[fieldName] = this.__form.cloneFieldValue(value, fieldName, cloneFromDefault)
      }
    }
  }

  increment(fieldName: string, valueToIncreaseBy: number = 1): number  {
    if (valueToIncreaseBy < 0) {
      throw new Error('Invalid argument exception. Can only increase not decrease')
    }

    return this.changeNumberBy(fieldName, valueToIncreaseBy)
  }

  decrement(fieldName: string, valueToDecreaseBy: number = 1): number {
    if (valueToDecreaseBy < 0) {
      throw new Error('Invalid argument exception. Can only decrease not increase')
    }

    return this.changeNumberBy(fieldName, -valueToDecreaseBy)
  }

  changeNumberBy(fieldName: string, valueToIncreaseBy: number = 1): number  {
    if (valueToIncreaseBy < 0.0000001 && valueToIncreaseBy > -0.0000001) {
      return
    }

    const fieldDefinition = this.form.getField(fieldName)

    if (!fieldDefinition) {
      throw new Error(`Field ${fieldName} is unknown`)
    }

    if (fieldDefinition.type !== 'number') {
      throw new Error('Can only increment/decrement numeric fields')
    }

    if (fieldDefinition.cardinality) {
      throw new Error('Can only apply increment/decrement to single cardinality fields')
    }

    if (!this.form.allowsUndefinedFields && this.__data[fieldName] === undefined) {
      throw new Error('Cannot increment/decrement field. It is undefined and the form does not allow undefined fields')
    }

    this.__changeOperations.push({
      type: 'increment',
      field: fieldName,
      delta: valueToIncreaseBy
    })

    const newValue = (this.__data[fieldName] ?? 0) as number + valueToIncreaseBy

    this.__data[fieldName] = newValue

    return newValue

    // NOTE: When proxies are used in the future for field accesses, subsequent
    // access via direct assignment should be forbidden
  }

  get locks(): Types.Reference[] {return this.__locks}
  set locks(locks: Types.Reference[]) {
    for (const l of locks) {
      if (!l.id || !l.v || l.text == null) {
        console.error('Attempt to set invalid locks', locks)
        throw new Error('Cannot set empty lock references (or references without version or null text. You cannot set documents directly)')
      }
    }
    
    const newLocks: Types.Reference[] = []
    for (const l of locks) {
      newLocks.push( {id: l.id, v: l.v, text: l.text })
    }

    const lockChanges = {
      added: cloneReferences(newLocks),
      updated: [],
      removed: [],
    }
    if (this.__locks) {
      lockChanges.removed = cloneReferences(this.__locks)
    }
    this.__lockChanges.push(lockChanges)

    this.__locks = newLocks
  }

  get stampData() {
    return this.__base.stampData
  }

  get stamps() {
    return this.__base.stamps
  }
  
  get t() {return this.__base.t}
  get ct() {return this.__base.ct}
  
  // The following properties should not be written to. Theoretically a user of the
  // api could get a document and alter these properties (e.g. push onto 'a') but
  // this is not a supported mode of interaction.
  //
  // Should this become an issue we will clone those fields too
  get created() {return this.__base.created}
  get modified() {return this.__base.modified}
 
  get a_depth() {return this.__base.a_depth}
  get a() {return this.__base.a}

  get a_forms() {return this.__base.a_forms}
  get inheritedLocks() {return this.__base.inheritedLocks}

  addLock(lock: Types.Document, options: AddLockOptions = null): boolean {
    if (!lock || !lock.f || lock.f.id !== 'lock-form') {
      throw new Error('Cannot only add reference to an actual lock document')
    }

    const opts = {...options}

    for (const existingLock of this.__locks) {
      if (existingLock.id === lock.id) {
        this.__lockChanges.push({
          added: [],
          updated: [lock],
          removed: []
        })

        return updateReference(existingLock, lock, opts.updateVersionIfAlreadyKnown)
      }
    }

    const newLockRef: Types.Reference = {
      id: lock.id,
      v: lock.v,
      text: <string>lock.data.name
    }

    this.__locks.push(newLockRef)
    this.__lockChanges.push({
      added: [newLockRef],
      updated: [],
      removed: []
    })
  }

  removeLock(lock: string|Types.Document|Types.Reference) {
    const id = typeof lock === 'string' ? lock : lock.id

    for (let i = this.__locks.length - 1; i >= 0; i -= 1) {
      const existingLock = this.__locks[i]

      if (existingLock.id === id) {
        const removedLocks = this.__locks.splice(i, 1)

        this.__lockChanges.push({
          added: [],
          updated: [],
          removed: removedLocks
        })

        return true
      }
    }

    return false
  }

  hasLock(lock?: Types.Document| Types.Reference) {
    if (!lock) {
      return (this.__locks.length > 0)
    }

    for (const existingLock of this.__locks) {
      if (existingLock.id === lock.id) {
        return true
      }
    }

    return false
  }

  hasInheritedLock(lock ?: Types.Document | Types.Reference) {
    if (!lock) {
      if (!this.__base.inheritedLocks) {
        return false
      }

      for (const locks of this.__base.inheritedLocks) {
        if (locks && locks.length > 0) {
          return true
        }
      }

      return false
    }

    for (const existingLocks of this.__base.inheritedLocks) {
      for (const existingLock of existingLocks) {
        if (existingLock.id === lock.id) {
          return true
        }
      }
    }

    return false
  }

  /** @internal */
  get lockChanges() {
    return this.__lockChanges
  }
  
  /** @internal */
  clearLockChanges() {
    this.__lockChanges = []
  }

  /** @internal */
  get ChangeOperations() {
    return this.__changeOperations
  }

  /** @internal */
  clearChangeOperations() {
    this.__changeOperations = []
  }

  /**
   * Reference helpers
   */

  // TODO: also allow just adding Ref
  addReference(fieldName: string, document: Types.Document, options: AddReferenceOptions = null): boolean {
    this._throwIfReadonly()

    const opts = { ...DEFAULT_ADDREFERENCE_OPTIONS, ...options }

    if (!fieldName) {
      throw new Error('Missing field name')
    }

    if (!document) {
      throw new Error('Missing document to reference')
    }

    const field = this.__form.getField(fieldName)

    if (!field) {
      throw new Error(`Field ${fieldName} does not exist on Form`)
    }
    
    if (!Form.isRefField(field)) {
      throw new Error(`Cannot add reference to non reference field ${fieldName}`)
    }

    if (!field.cardinality) {
      const existingReference = this.data[fieldName] 
      
      if (existingReference) {
        return updateReference(existingReference, document, opts.updateVersionIfAlreadyKnown)
      } else {
        this.data[fieldName] = {
          id: document.id,
          v: document.v,
          text: document.data.name
        }
      }
    } else {
      let existingReferences = this.data[fieldName] 
      
      if (!existingReferences) {
        existingReferences = this.data[fieldName] = []
      }

      if (opts.allowMultiple) {
        const newReference = {
          id: document.id,
          v: document.v,
          text: document.data.name
        }

        if (opts.addToBeginning) {
          existingReferences.unshift(newReference)
        } else {
          existingReferences.push(newReference)
        }
      } else {
        const existingReference = existingReferences.filter((ref) => ref.id === document.id)[0]

        if (existingReference) {
          return updateReference(existingReference, document, opts.updateVersionIfAlreadyKnown)
        } else {
          const newReference = {
            id: document.id,
            v: document.v,
            text: document.data.name
          }

          if (opts.addToBeginning) {
            existingReferences.unshift(newReference)
          } else {
            existingReferences.push(newReference)
          }
        }
      }
    }
  }

  removeReference(fieldName: string, documentIdentifier ?: Types.IdOrReference): boolean {
    this._throwIfReadonly()

    if (!fieldName) {
      throw new Error('Missing field name')
    }

    let documentId: string
    
    if (documentIdentifier) {
      documentId = typeof documentIdentifier === 'string' ? documentIdentifier : documentIdentifier.id
    }

    const field = this.__form.getField(fieldName)

    if (!field) {
      throw new Error(`Field ${fieldName} does not exist on Form`)
    }
    
    if (!Form.isRefField(field)) {
      throw new Error(`Cannot remove reference from non reference field ${fieldName}`)
    }

    if (!field.cardinality) {
      let existingReference = this.data[fieldName]

      if (!existingReference) {
        existingReference = this.data[fieldName] = { id: '', v: '', text: '' } 
      }

      if (!documentId || existingReference.id === documentId) {
        existingReference.id = ''
        existingReference.v = ''
        existingReference.text = ''
      } else {
        return false
      }
    } else {
      let existingReferences: Types.Reference[] = this.data[fieldName] 
      
      if (!existingReferences) {
        existingReferences = this.data[fieldName] = []
      }
      
      for (let i = 0; i < existingReferences.length; i += 1) {
        const existingReference = existingReferences[i]

        if (existingReference.id === documentId) {
          existingReferences.splice(i, 1)
          return true
        }
      }

      return  false
    }

    return true
  } 

  isReferencing(fieldName: string = null, documentIdentifier: Types.IdOrReference, options?: { strict?: boolean } ) {
    if (fieldName) {
      const field = this.data[fieldName]
      return isFieldReferencing(field, documentIdentifier, options)
    } else {
      for (const { name } of this.__form.fields) {
        const field = this.data[fieldName]
        return isFieldReferencing(field, documentIdentifier, options)
      }
    }
  }
  
  /**
   * Set a file field to the desired content
   * 
   * In case of multi file fields it resets it to an array with 
   * only one value.
   * 
   * If the specified value is null or undefined the field is
   * reset to empty. So either field:null or field:[] depending
   * on cardinality 
   * 
   * @param fieldName   [string] Name of the field (must be of type File/Image)
   * @param content     [string|Node.js Buffer|File|Blob]  
   * @param options     Options
   */
  setFile(fieldName: string, content: string|Buffer | File | Blob | ArrayBuffer | NodeJS.ReadableStream, options ?: FileSetOptions) {
    this._throwIfReadonly()

    if (!this.__createFileURL) {
      throw new Error('Cannot set file since createFileURL option not configured')
    }

    const fieldInfo = this.__form.getField(fieldName)

    if (!fieldInfo) {
      throw new Error('Unknown field')
    }

    if (!Form.isFileField(fieldInfo)) {
      throw new Error(`Field ${fieldName} must be a File/Image field but is ${fieldInfo.type}/${fieldInfo.subType}`)
    }

    if (Form.isMultiField(fieldInfo)) {
      throw new Error(`File fields with cardinality > 1 are not supported ${this.__form.id}@${this.__form.v} [${fieldName}] = ${JSON.stringify(fieldInfo, null, 2)}`)
    }

    if (!content) {
      if (Form.isMultiField(fieldInfo)) {
        this.data[fieldName] = []
      } else {
        this.data[fieldName] = null
      }

      return
    }

    const fileFieldValue: FileField = {
      name: null,
      size: null,
      type: null,
      sha1: null,
      dataUrl: null,
      thumbnailUrl: null,
      iconUrl: null,
      fileInfo: {
        created: null,
        read: null,
        modified: null,
        imported: new Date().valueOf(),
      }
    }           
              
    if (fieldInfo.subType === 'image') {
      fileFieldValue.mediaInfo = {
        width: null,
        height: null
      }
    }

    if (typeof content === 'string') {
      this._readDataUrlIntoFileField(fileFieldValue, content)
    } else if (typeof Buffer !== 'undefined' && Buffer.isBuffer(content)) {
      this._readBufferIntoFileField(fileFieldValue, content)
    } else if (typeof File !== 'undefined' && content instanceof File) {
      this._readFileIntoFileField(fileFieldValue, content)
    } else if (typeof Blob !== 'undefined' && content instanceof Blob) {
      this._readBlobIntoFileField(fileFieldValue, content)
    } else if (content && (<NodeJS.ReadableStream>content).pipe) {
      this._readStreamIntoFileField(fileFieldValue, <NodeJS.ReadableStream>content)
    } else {
      throw new Error('Unsupported content type')
    }

    if (options) {
      if (options.name) {
        fileFieldValue.name = options.name
      }

      if (options.type) {
        fileFieldValue.type = options.type
      }
    }

    if (this.__pendingFileUploadFields === null) {
      this.__pendingFileUploadFields = new Set()
    }

    this.__pendingFileUploadFields.add(fieldName)
    this.__data[fieldName] = fileFieldValue 
  }
  
  /** @internal */
  public retrieveUploads() {
    if (!this.__pendingFileUploadFields) {
      return null
    }
    
    const fieldNames = Array.from(this.__pendingFileUploadFields.keys())
    this.__pendingFileUploadFields = null
    return fieldNames
  }

  private _readBufferIntoFileField(fileField: FileField, content: Buffer) {
    fileField.type = 'application/octet-stream'
    fileField.size = content.byteLength
    fileField.dataUrl = this.__createFileURL(<any>content)
  }

  private _readBlobIntoFileField(fileField: FileField, content: Blob) {
    fileField.type = content.type
    fileField.size = content.size
    fileField.dataUrl = this.__createFileURL(content)
  }

  private _readFileIntoFileField(fileField: FileField, content: File) {
    this._readBlobIntoFileField(fileField, content)
    fileField.name = content.name
    fileField.fileInfo.modified = content.lastModified && content.lastModified.valueOf ? content.lastModified.valueOf() : null
  }

  private _readStreamIntoFileField(fileField: FileField, content: NodeJS.ReadableStream) {
    fileField.dataUrl = this.__createFileURL(<any>content)
  }

  private _readDataUrlIntoFileField(fileField: FileField, dataUrl: string) {
    const protocol = dataUrl.slice(0, 5)
    
    if (protocol !== 'data:') {
      throw new Error('text must be a data-uri to be writeable into file field')
    }  

    const indexOfFirstCommaAndThusBeginningOfEncodedData = dataUrl.indexOf(',', 5)

    if (indexOfFirstCommaAndThusBeginningOfEncodedData > 5) {
      const textBetweenColonAndComma = dataUrl.substring(5, indexOfFirstCommaAndThusBeginningOfEncodedData)
      const [mediatype, encodingToken] = textBetweenColonAndComma.split(';', 2)

      if (encodingToken && encodingToken !== 'base64') {
        throw new Error('Invalid data-uri. data:[<mediatype>][;base64|charset=<US-ASCII>],<data>')
      }

      fileField.type = mediatype || DEFAULT_MIME_TYPE
    } else {
      fileField.type = DEFAULT_MIME_TYPE
    }

    fileField.dataUrl = this.__createFileURL(<any>dataUrl)
  }
}

function updateReference(reference, targetDocument, updateVersionIfAlreadyKnown) {
  if (reference.id === targetDocument.id) {
    if (!updateVersionIfAlreadyKnown) {
      return false
    }
  } else {
    reference.id = targetDocument.id
  }

  reference.v = targetDocument.v
  reference.text = targetDocument.data.name

  return true
}

function cloneReferences(refs: Types.Reference[]): Types.Reference[] {
  if (refs == null || refs.length === 0) {
    return []
  } else {
    return refs.map(cloneReference)
  }
}

function cloneReference(ref: Types.Reference): Types.Reference {
  if (ref == null) {
    return ref
  } else {
    return { id: ref.id, v: ref.v, text: ref.text}
  }
}

function fixOldStyleReference(ref) {
  if (!ref) {
    return ref
  }

  if (ref.id === '' && ref.v === 0 && !ref.text) {
    return { id: '', v: '', text: '' }
  } else if (typeof ref.v === 'number') {
    return { id: ref.id, v: 'converted', text: ref.text }
  } else {
    return ref
  }
}

function isFieldReferencing(refField, documentIdentifier: Types.IdOrReference, options?: { strict?: boolean }) {
  if (refField && Array.isArray(refField)) {
    for (const ref of refField) {
      if (isReferenceTo(ref, documentIdentifier, options)) {
        return true
      }
    }
    return false
  } else {
    return isReferenceTo(refField, documentIdentifier, options)
  }
}

function isReferenceTo(ref: Types.Reference, documentIdentifier: Types.IdOrReference, options?: { strict?: boolean }) {
  const { id, v } = typeof documentIdentifier === 'string' ? { id: documentIdentifier, v: null } : documentIdentifier

  if (ref && ref.id === id) {
    if (options && options.strict && v) {
      return (ref.v === v)
    } else {
      return true
    }
  }

  return false
}

function isSymbol(value: any) {
  return typeof value === 'symbol' || (typeof value === 'object' && Object.prototype.toString.call(value) === '[object Symbol]')
}

function isDynamicVueProperty(property: any) {
  if (typeof property !== 'string') {
    return false
  }

  return property.startsWith('__v_') || property == '__ob__'
}