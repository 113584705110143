
import { debounce } from '../utils/debounce.js'
import { SerialPromiseExecutor } from '../utils/serial-promise-executor.js'
import { themeDocumentToTheme } from '../form/theme.js'
import { getFirstNonEmptyString } from '../utils/lookup.js'
import { FormView } from '../form/view.js'
import { Form } from '../../model/form.js'

const TABBED_FORM_LAYOUT = 'a34ed21c-7246-45ae-9f9c-9e6148ad92cf'
const EXPERIMENTAL_FORM_LAYOUT = '6c39f2cc-6059-42b2-8796-5c803899367c'

export default {
  /* tslint:disable */
  render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.renderError)?_c('pre',{staticClass:"ae-error"},[_vm._v(_vm._s(_vm.renderError))]):(_vm.renderedDocument && _vm.viewToUse && _vm.formToRender)?_c('ae-layout',{key:_vm.renderedDocument.id + _vm.formToRender.id + _vm.formToRender.v,staticClass:"ae-form--layout",class:_vm.formClasses,attrs:{"watch-layout-revisions":_vm.watchLayoutRevisions,"layout-id":_vm.formLayoutId,"param-document":_vm.renderedDocument,"param-form":_vm.formToRender,"param-readonly":_vm.readonly,"param-root-section":_vm.rootSection,"param-field-conditions":_vm.fieldConditions,"param-theme":_vm.appliedTheme,"param-view":_vm.viewToUse}}):_vm._e()},
  staticRenderFns: [],
  /* tslint:enable */
  
  inject: ['getAeppicContext'],
  props: {
    document: Object,
    documentId: String,
    layoutId: String,
    watch: {
      type: Boolean,
      default: true
    },
    watchLayoutRevisions: {
      type: Boolean,
      default: null
    },
    watchFormRevisions: {
      type: Boolean,
      default: true
    },
    autoSave: {
      type: Boolean,
      default: false
    },
    autoSaveAfter: {
      type: Number,
      default: 1000
    },
    readonly: {
      type: Boolean,
      default: false
    },
    // A document (does not have to be stored in the model yet) usable
    // as a form description
    form: Object,
    theme: Object,
    view: Object,
  },
  computed: {
    isEditing() {
      return !!this.aeEditableDocument
    },
    isEditingExternally() {
      return this.isEditing && this.aeEditableDocument === this.documentToBindTo
    },
    renderedDocument() {
      return this.temporaryDocumentForForm || this.aeEditableDocument || this.documentToBindTo
    },
    renderedDocumentData() {
      const doc = this.renderedDocument
      return doc && doc.data
    },
    viewToUse() {
      if (!this.renderedDocument || !this.formToRender) {
        return
      }

      if (this.viewCache.view && this.viewCache.form === this.formToRender && this.viewCache.document) {
        if (this.viewCache.document === this.renderedDocument) {
          return this.viewCache.view
        }

        if (!this.Aeppic.isEditableDocument(this.renderedDocument)) {
          if (this.viewCache.document.id === this.renderedDocument.id) {
            return this.viewCache.view
          }
        }
      }

      this.refreshViewCache()

      if (this.renderedDocument.id === this.viewCache.document?.id && this.viewCache.form === this.formToRender) {
        return this.viewCache.view
      }
    },
    rootSection() {
      return this.viewToUse?.rootSection
    }
  },
  data() {
    return {
      Aeppic: this.getAeppicContext('ae-form'),
      documentToBindTo: null,
      fieldConditions: null,
      temporaryDocumentForForm: null,
      aeEditableDocument: null,
      deleted: false,
      formToRender: null,
      renderError: null,
      formLayoutId: null,
      formClasses: [],
      viewCache: { updateIndex: 0, view: null, document: null, form: null },
      appliedTheme: null,
    }
  },
  created() {
    this.$_loadDocumentExecutor = new SerialPromiseExecutor()

    this.$_loadDocument = async () => {
      if (this.isEditing && !this.readonly) {
        const editingDocumentId = this.aeEditableDocument.id
        const documentIsSame = this.documentId === editingDocumentId || (this.document && this.document.id === editingDocumentId)

        const newOrCurrentForm = this.form || this.formToRender

        if (newOrCurrentForm) {
          const formIsSame = this.aeEditableDocument.f.id === newOrCurrentForm.id &&
            this.aeEditableDocument.f.v === newOrCurrentForm.v
  
          if (documentIsSame && formIsSame) {
            return
          }
        }
      }

      this.documentToBindTo = null
      this.aeEditableDocument = null

      this.stopWatchingDocument()
      this.stopWatchingDataChanges()

      if (this.document) {
        this.documentToBindTo = await this.document

        if (this.Aeppic.isEditableDocument(this.documentToBindTo)) {
          this.aeEditableDocument = this.documentToBindTo
          this.startWatchingDataChanges()
        }
      } else if (this.documentId) {
        this.documentToBindTo = await this.Aeppic.get(this.documentId)
      } else {
        this.documentToBindTo = null
      }

      await this.loadFormToRender()

      this.startWatchingDocument()

      if (!this.isEditingExternally && !this.readonly) {
        await this.startEditing()
      }
    }

    if (this.autoSave) {
      this.debouncedSave = debounce(() => {
        this.save()
      }, this.autoSaveAfter)
    }

    this.renderTemporaryFormDebounced = debounce(() => {
      this.renderTemporaryForm()
    })

    this._formDocumentsWatcher = null
    this._latestFormRevision = null
    this.temporaryDocumentForForm = null

    this.loadDocument()
  },
  mounted() {
    this.Aeppic.setContextRootElement(this.$el)
  },
  beforeDestroy() {
    if (this.autoSave) {
      this.save()
    }

    this.cancelEditing()
    this.Aeppic.release()
  },
  watch: {
    watch() {
      this.stopWatchingDocument()
      this.startWatchingDocument()
    },
    document() {
      this.loadDocument()
    },
    documentId() {
      this.loadDocument()
    },
    readonly() {
      if (this.readonly) {
        this.cancelEditing()
      } else {
        this.startEditing()
      }
    },
    viewToUse: {
      handler() {
        this.refreshView()
      },
      deep: true
    },
    form: {
      handler() {
        this.loadDocument()
      },
      deep: true
    },
    renderedDocumentData: {
      handler() {
        this.refreshView()
      },
      deep: true
    }
  },
  methods: {
    async refreshViewCache() {
      const viewCacheId = ++this.viewCache.updateIndex

      const editable = this.Aeppic.isEditableDocument(this.renderedDocument) ? this.renderedDocument : await this.Aeppic.edit(this.renderedDocument)

      // this.Aeppic.Vue.set(this.viewCache, 'view', null).set(this.viewCache, 'view', null)
      this.viewCache.view = null

      if (viewCacheId < this.viewCache.updateIndex) {
        return
      }

      if (!this.renderedDocument || !this.formToRender) {
        return
      }

      const newView = new FormView(editable, this.formToRender, this.view)

      this.viewCache = {
        updateIndex: this.viewCache.updateIndex,
        view: newView,
        document: editable,
        form: this.formToRender,
      }
    },
    async loadDocument() {
      this.$_loadDocumentExecutor.runNext(async () => await this.$_loadDocument())
    },
    async loadFormToRender() {
      this.stopWatchingForFormDocumentsChanges()


      if (!this.documentToBindTo) {
        this.formLayoutId = null
        this.formToRender = null
        return
      }

      if (this.form) {
        this.formToRender = this.form.info ? this.form : this.Aeppic.buildTemporaryForm(this.form)
      } else {
        this.formToRender = await this.Aeppic.getFormForDocument(this.documentToBindTo)
      }

      this.startWatchingForFormDocumentsChanges(this.formToRender.id)

      const optionalExperimentalLayout = await this.applyExperimentalRenderingMethod()
      await this.applyTheme()

      this.formLayoutId = getFirstNonEmptyString(this.layoutId, this.appliedTheme.form.layout, optionalExperimentalLayout) ?? TABBED_FORM_LAYOUT
      this.formClasses = this.appliedTheme.form.classes

      this.refreshView()
    },

    startWatchingDocument() {
      if (!this.watch) {
        return
      }

      if (!this.documentToBindTo) {
        return
      }

      this.watcher = this.Aeppic.watch(this.documentToBindTo.id, onChange.bind(this))

      function onChange(changedDocument, reason) {
        if (reason.deletedHard) {
          this.documentToBindTo = null
          this.deleted = true
          return
        }

        if (!this.isEditingExternally) {
          this.documentToBindTo = changedDocument
        }

        this.handleChanges(changedDocument, reason)
      }
    },
    stopWatchingDocument() {
      if (this.watcher) {
        this.watcher.stop()
        this.watcher = null
      }
    },
    async startEditing() {
      if (this.isEditingExternally) {
        this.$emit('start-editing', this.aeEditableDocument)
      } else {
        if (!this.documentToBindTo) {
          return
        }

        if (this.readonly) {
          return
        }

        if (this.isEditing) {
          this.cancelEditing()
        }

        this.aeEditableDocument = await this.Aeppic.edit(this.documentToBindTo.id)
      }

      this.startWatchingDataChanges()
    },
    startWatchingDataChanges() {
      this.cancelWatchingEditData = this.$watch('aeEditableDocument.data', onDataChanged.bind(this), { deep: true })

      function onDataChanged() {
        if (this.aeEditableDocument) {
          if (this.isEditingExternally) {
            this.$emit('data-changed', this.aeEditableDocument)
          } else {
            this.Aeppic.commit(this.aeEditableDocument)
          }

          if (this.autoSave) {
            this.debouncedSave()
          }
        }
      }
    },
    stopWatchingDataChanges() {
      if (this.cancelWatchingEditData) {
        this.cancelWatchingEditData()
        this.cancelWatchingEditData = null
      }
    },
    startWatchingTemporaryDocumentForFormData() {
      this.stopWatchingTemporaryDocumentForFormData()

      this.cancelWatchingTemporaryDocumentForFormData = this.$watch('temporaryDocumentForForm.data', onDataChanged.bind(this), { deep: true })

      function onDataChanged() {
        if (this.temporaryDocumentForForm) {
          this.formToRender.applyOptionalFieldRules(this.temporaryDocumentForForm.data)
          this.formToRender.updateCalculatedFields(this.temporaryDocumentForForm.data)
        }
      }
    },
    stopWatchingTemporaryDocumentForFormData() {
      if (this.cancelWatchingTemporaryDocumentForFormData) {
        this.cancelWatchingTemporaryDocumentForFormData()
        this.cancelWatchingTemporaryDocumentForFormData = null
      }
    },
    startWatchingForFormDocumentsChanges(formId) {
      this.stopWatchingForFormDocumentsChanges()

      if (!this.watchFormRevisions || !formId) {
        return
      }

      this._formDocumentsRevisionWatcher = this.Aeppic.watchRevisions(formId, (document) => {
        this._latestFormRevision = document

        this.renderTemporaryFormDebounced()
      })

      this._formDocumentsWatcher = this.Aeppic.watch(formId, async (document) => {
        if (this._latestFormRevision) {
          this._latestFormRevision = null
          this.temporaryDocumentForForm = null

          this.stopWatchingForFormDocumentsChanges()

          await this.loadFormToRender()

          this.startWatchingForFormDocumentsChanges()
        }
      })
    },
    renderTemporaryForm() {
      if (this._latestFormRevision) {
        const previousDocumentForExistingData = this.temporaryDocumentForForm || this.aeEditableDocument || this.documentToBindTo
        const previousFormForExistingData = this.formToRender || this.Aeppic.getDocumentForm(previousDocumentForExistingData)

        this.temporaryDocumentForForm = null
        this.formToRender = this.Aeppic.buildTemporaryForm(this._latestFormRevision)
        this.temporaryDocumentForForm = this.Aeppic.buildTemporaryEditableDocument(this.formToRender)

        this.copyDataFields(this.temporaryDocumentForForm.data, this.formToRender, previousDocumentForExistingData.data, previousFormForExistingData)
        this.startWatchingTemporaryDocumentForFormData()
      }
    },
    async applyExperimentalRenderingMethod() {
      if (!this.formToRender) {
        return
      }

      const featureEnabled = await this.Aeppic.Features.isEnabled('forms-rendering-experimental')
      const taggedWithExperimentalRendering = this.formToRender.data.tags?.includes('experimental-rendering')

      if (featureEnabled && taggedWithExperimentalRendering) {
        const layout = this.Aeppic.Features.getOptionsFor('forms-rendering-experimental')?.layoutId ?? EXPERIMENTAL_FORM_LAYOUT
        return layout
      } else {
        return
      }
    },
    async applyTheme() {
      if (this.theme) {
        this.appliedTheme = this.theme
      } else {
        const themeDocument = await this.Aeppic.get(this.formToRender?.data.theme)
        this.appliedTheme = await themeDocumentToTheme(themeDocument)
      }

      // if (!this.theme.form.layout) {
      //   this.theme.form.layout = FORM_SECTION_LAYOUT
      // }
    },
    copyDataFields(targetData, targetForm: Form, sourceData, sourceForm: Form) {
      targetForm.copyFieldsFromDifferentForm(targetData, sourceData, sourceForm)
      targetForm.applyOptionalFieldRules(targetData)
      targetForm.updateCalculatedFields(targetData)
    },
    stopWatchingForFormDocumentsChanges() {
      this.temporaryDocumentForForm = null
      this._latestFormRevision = null

      if (this._formDocumentsRevisionWatcher) {
        this._formDocumentsRevisionWatcher.stop()
        this._formDocumentsRevisionWatcher = null
      }

      if (this._formDocumentsWatcher) {
        this._formDocumentsWatcher.stop()
        this._formDocumentsWatcher = null
      }

      this.stopWatchingTemporaryDocumentForFormData()
    },
    refreshView() {
      if (this.formToRender && this.renderedDocument) {
        this.fieldConditions = this.formToRender.evaluateConditions(this.renderedDocument.data)
      } else {
        this.fieldConditions = {}
      }

      this.viewToUse?.refresh(this.fieldConditions)
    },
    save() {
      if (this.isEditing) {
        if (this.isEditingExternally) {
          this.$emit('save', this.aeEditableDocument)
        } else if (!this.Aeppic.Model.Recycler.contains(this.aeEditableDocument.id)) {
          this.Aeppic.save(this.aeEditableDocument)
        }
      }
    },
    saveAndStopEditing() {
      this.save()
      this.cancelEditing()
    },
    cancelEditing() {
      if (this.isEditingExternally) {
        this.$emit('cancel', this.aeEditableDocument)
      } else if (this.isEditing) {
        this.stopWatchingDataChanges()
        this.aeEditableDocument = null
      }
    },
    resetEdits() {
      this.startEditing()
    },
    async handleChanges(changedDocument, changes) {
      if (this.isEditing) {
        this.Aeppic.integrate(this.aeEditableDocument, changedDocument)

        await this.loadFormToRender()
      }
    }
  },
}

