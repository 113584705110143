import * as Types from '@aeppic/types'
import { parse, markdown, ParsedTags } from '@aeppic/forms-parser'
import { ParsedField, ParsedTag, ParsedFormInfo, ParsedFormSection, ParsedPlaceholder } from '@aeppic/forms-parser'
import { camelCase } from '@aeppic/shared/camel-case'
import { DateTime, Duration as DateTimeDuration } from 'luxon'
import { lookupDefaultControlName } from '../aeppic/utils/control-names.js'

// import Document from './document'
import Reference from './reference.js'

import { buildLogger } from './log.js'

const log = buildLogger('form')

export type { ParsedPlaceholder }

import createControllerClass from '../aeppic/create-controller-class.js'
import { FieldFormatter, isKnownFieldFormatterFunction } from './field-formatter.js'

export type TagMatch = string | RegExp | { name: string | RegExp, value: string | RegExp }
export type TagMatchOptions = { operation?: 'and' | 'or' }
export type TagMatchInfo = {
  name?: string
  value?: string
  matchName?: RegExp
  matchValue?: RegExp
}
export type FileField = {
  name: string
  size: number
  type: string
  sha1: string
  dataUrl: string,
  thumbnailUrl: string,
  iconUrl: string,
  fileInfo: {
    created: number,
    read: number,
    modified: number,
    imported: number
  }
  mediaInfo?: {
    width: number,
    height: number
  }
}

export class FormVersions {
 
  // all versions latest version is index 0
  private _deleted = false
  private _versions = new Map<string, Form>()

  constructor(private _id: string) {}

  get id() {
    return this._id
  }

  delete() {
    this._deleted = true
  }

  get deleted() {
    return this._deleted
  }

  has(version: string): boolean {
    return  this._versions.has(version)
  }

  getVersions(): string[] {
    const forms: Form[] = Array.from(this._versions.values())
    forms.sort((a, b) => a.compare(b))
    return forms.map(f => f.v)
  }

  getLatestVersion(): string {
    const versions = this.getVersions()
    return versions[versions.length - 1]
  }

  add(formDocument: Types.Document) {
    if (formDocument.id !== this._id) {
      throw new Error(`Invalid form document argument. Wrong id`)
    }

    if (formDocument.f.id !== 'form') {
      throw new Error('Invalid form document argument. Requires document of type `form`')
    }

    const form = new Form(formDocument)
    this._versions.set(form.v, form)
  }

  get(version: string): Form {
    return this._versions.get(version)
  }

  getAll(): Form[] {
    return Array.from(this._versions.values())
  }
}

function isInitialFormForm(form: Form) {
  return (form.id === 'form' && form.v === 'initial')
}

export interface FormOptions {
  version?: string
  temporary?: boolean
}

export type FieldInfo = ParsedField

export type PlaceholderCondition = {
  id: string
  expression: string
}

export interface IFormSection {
  get index(): number
  get title(): string
  get type(): 'ROOT'|'ATX'
  get tags(): ParsedTags
  get level(): number
  get style(): any
  get paragraphs(): IFormSectionParagraph[]
  get subSections(): IFormSection[]
  get offset(): number
}

export class FormSection implements IFormSection {
  private _section: any
  private _paragraphs: IFormSectionParagraph[] = null
  private _subSections: IFormSection[] = null

  constructor(section: ParsedFormSection, formInfo: ParsedFormInfo) {
    this._section = section
    this._paragraphs = section.paragraphs.map((p: any) => new FormSectionParagraph(p, formInfo))
    this._subSections = (section.directSubsections ?? []).map((s: any) => new FormSection(s, formInfo))
  }

  public get offset() {
    return this._section.offset
  }

  public get index() {
    return this._section.index
  }

  public get title() {
    return this._section.title
  }

  public get type() {
    return this._section.type
  }

  public get level() {
    return this._section.level
  }

  public get style() {
    return this._section.style
  }

  public get tags() {
    return this._section.tags
  }

  public get paragraphs() {
    return this._paragraphs
  }

  public get subSections() {
    return this._subSections
  }
}

// TODO: Implement inline with text mode
// export class ParagraphItemType {
//   static TEXT = 'TEXT'
//   static FIELD = 'FIELD'
//   static STANDALONE_CONTROL = 'STANDALONE_CONTROL'
// }

// export class ParagraphType {
//   // static TEXT = ParagraphItemType.TEXT
//   // static FIELD = ParagraphItemType.FIELD
//   // static STANDALONE_CONTROL = ParagraphItemType.STANDALONE_CONTROL
//   // static INLINE = 'INLINE'
//   // static INLINE_WITH_TEXT = 'INLINE_WITH_TEXT'
// }

export interface ControlParameters {
  [name: string]: {
    value: string
  }
}

/*
 * 
 * @example
 * const test: FormControlInfo = { 
 *   "namespace": "",
 *   "name": "ref",
 *   "fullName": "ref",
 *   "parameters": {
 *     "hide-path": {
 *       "value": "true"
 *      },
 *      "ancestor-search-limit": {
 *        "value": "2"
 *      },
 *      "handle-selected": {
 *        "value": "false"
 *      }
 *    }
 *  }
 */

// export interface IFormParagraphItem {
//   readonly type: string
// }

// export function isFieldItem(item: IFormParagraphItem): item is FormFieldAtPlaceholder {
//   return (item.type === ParagraphItemType.FIELD)
// }

export interface IFormParagraphTextItem {
  readonly type: 'TEXT'
  readonly markdown: string
  readonly html: string
}

export class FormParagraphTextItem implements IFormParagraphTextItem {
  public readonly type = 'TEXT'
  private _html: string = null

  constructor(private _markdown: string) {
    if (this._markdown == null) {
      this._html = ''
    }
  }

  /**
   * @param What part of the formdownDefinition to extract. From index `from` up to, but not including, `to`
   * @returns The form paragraph which can read the html
   */
  static fromDefinition(formdownDefinition: string, { from, to }: { from: number, to: number }) {
    if (to <= from) {
      return null
    }

    const markdown = formdownDefinition.substring(from, to)

    if (markdown.trim().length === 0) {
      return null
    }

    return new FormParagraphTextItem(markdown)
  }

  get markdown() { return this._markdown }

  get html() {
    if (this._html === null) {
      this._html = markdown(this._markdown)
    }

    return this._html    
  }
}

type FormControlParameters = {
  [key: string]: string|boolean
}


export interface IFormStandaloneControl {
  readonly type: 'STANDALONE_CONTROL'

  readonly name: string
  readonly parameters: FormControlParameters
  readonly placeholder: ParsedPlaceholder
}

export class FormStandaloneControl implements IFormStandaloneControl {
  public readonly type = 'STANDALONE_CONTROL'

  private _placeholder: ParsedPlaceholder
  private _parameters: FormControlParameters

  constructor(placeholderInfo: ParsedPlaceholder) {
    this._placeholder = placeholderInfo
    this._parameters = toFormControlParameters(placeholderInfo.control.parameters)
  }

  get name() { return this._placeholder.control.fullName }
  get placeholder() { return this._placeholder }
  get parameters() { return this._parameters }
}

function toFormControlParameters(parameters: ControlParameters) {  
  const normalizedParams = {}

  if (!parameters) {
    return normalizedParams
  }

  for (const key in parameters) {
    const camelCasedKey = camelCase(key)
    const value = parameters[key].value

    if (value === 'true') {
      normalizedParams[camelCasedKey] = true
    } else if (value === 'false') {
      normalizedParams[camelCasedKey] = false
      continue
    } else {
      normalizedParams[camelCasedKey] = value
    }
  }

  return normalizedParams
}

export interface StyleParameters {
  [name: string]: {
    value: string
    condition?: {
      id: string,
      expression: string
    }
  }
}

export interface IFormFieldAtPlaceholder {
  readonly type: 'FIELD'
  readonly name: string
  readonly label: string
  readonly controlName: string
  readonly controlParams: ControlParameters
  readonly offset: number
  readonly placeholder: ParsedPlaceholder
}

export class FormFieldAtPlaceholder implements IFormFieldAtPlaceholder {
  public readonly type = 'FIELD'

  private _placeholder: ParsedPlaceholder
  private _controlName: string
  private _controlParameters: ControlParameters

  constructor(placeholderInfo: ParsedPlaceholder) {
    this._placeholder = placeholderInfo
    
    const controlName = placeholderInfo.control?.name || lookupDefaultControlName(placeholderInfo.field)
    this._controlName = placeholderInfo.control?.namespace ? `${placeholderInfo.control.namespace}:${controlName}` : controlName

    this._controlParameters = toFormControlParameters(placeholderInfo.control?.parameters)
  }

  public get offset() {
    return this._placeholder.offset
  }

  public get name() {
    return this._placeholder.field.name
  }

  public get label() {
    return this._placeholder.label
  }

  public get controlName() {
    return this._controlName 
  }

  public get controlParams() {
    return this._controlParameters
  }

  public get placeholder() {
    return this._placeholder
  } 
}

export type IFormParagraphItem = IFormParagraphTextItem | IFormStandaloneControl | IFormFieldAtPlaceholder

export interface IFormSectionParagraph {
  readonly type: string
  readonly items: IFormParagraphItem[]
  readonly offset: number
}

const EMAIL_REGEX = /^[^@\s\t\r\n]+@[^@\s\t\r\n]+\.[^@\s\t\r\n]+$/m

// private _renderToHtml(): string {
//   const offset = this._paragraph.offsetExcludeDirectives
//   const length = this._paragraph.length - (this._paragraph.offsetExcludeDirectives - this._paragraph.offset)
  
//   const markdownInParagraph = this._definition.substring(offset, offset + length)
//   return markdown(markdownInParagraph)
// }

function buildItemsInParsedParagraph(paragraph: any, formDefinition: string) {
  const items: IFormParagraphItem[] = []

  const { hasText } = paragraph
  const paragraphOffset = paragraph.offsetExcludeDirectives
  const paragraphLength = paragraph.length - (paragraph.offsetExcludeDirectives - paragraph.offset)

  const paragraphDefinition = formDefinition.substring(paragraphOffset, paragraphOffset + paragraphLength)

  let offset = 0

  for (const placeholder of paragraph.placeholders) {
    if (hasText) {
      const textItem = FormParagraphTextItem.fromDefinition(paragraphDefinition, { from: offset, to: offset + placeholder.offset} )
      
      if (textItem) {
        items.push(textItem)
      }
    }

    const isField = Boolean(placeholder.field)

    if (isField) {
      items.push(new FormFieldAtPlaceholder(placeholder))
    } else {
      const isControl = Boolean(placeholder.control) 

      if (isControl) {
        const control = new FormStandaloneControl(placeholder)
        items.push(control)
      }
    }
    
    offset += placeholder.length
  }

  if (hasText) {
    const textElement = FormParagraphTextItem.fromDefinition(paragraphDefinition, { from: offset, to: offset + paragraphLength } )
      
    if (textElement) {
      items.push(textElement)
    }
  }

  return items
}

class FormSectionParagraph implements IFormSectionParagraph {
  private _type: 'TEXT' | 'INLINE_WITH_TEXT' | 'INLINE'
  private _paragraph: any
  private _definition: string
  private _items: IFormParagraphItem[] = null

  constructor(paragraph: any, { definition }) {
    this._paragraph = paragraph
    this._definition = definition

    this._type = calculateParagraphType(paragraph)    
  }

  get offset(): number {
    return this._paragraph.offset
  }

  get type() {
    return this._type
  }

  get items(): IFormParagraphItem[] {
    if (this._items === null) {
      this._items = buildItemsInParsedParagraph(this._paragraph, this._definition)
    }

    return this._items
  }
}

let stopOnce = true

function calculateParagraphType(paragraph: any) {
  if (paragraph.hasPlaceholders) {
    if (paragraph.hasText) {
      console.warn('No supported yet')
      return 'INLINE_WITH_TEXT'
    } else {
      return 'INLINE'
    }
  } else if (paragraph.hasText) {
    return 'TEXT'
  }
}

export type FormConditionResults = {
  [conditionId: string]: any
}

export type FormOptionalFieldResults = {
  [fieldName: string]: boolean
}

export type FormFormattingResults = {
  // True if a value was changed due to formatting
  [fieldName: string]: boolean
}

const BUILT_IN_FORMATTER = new FieldFormatter()

export class Form {
  public document: Types.Document

  private _info: ParsedFormInfo = null
  private _fields: FieldInfo[]
  // private _definition: string

  private _formController = null
  private _rootSection: IFormSection = null
  private _temporary = false

  constructor(document: Types.Document, options?: FormOptions) {
    this.document = document
    this._info = null
    this._fields = null
    this._temporary = options?.temporary ?? false

    if (options && options.version) {
      // this.v = options.version
    }
  }

  get isTemporary() {
    return this._temporary
  }

  compare(form: Form) {
    let c = null

    if (this.document.t && form.document.t) {
      c = this.document.t - form.document.t
    } else {
      c = this.document.created.at.localeCompare(form.document.created.at)
    }

    if (c === 0) {
      c = this.document.v.localeCompare(form.document.v)
    }

    return c
  }

  get allowsUndefinedFields() {
    if ('allowUndefinedFields' in this.document.data) {
      return this.document.data.allowUndefinedFields as boolean
    }

    return false
  }

  validateFieldValue(fieldSelector: string|ParsedField, value: any) {
    const field = typeof fieldSelector === 'string' ? this.getField(fieldSelector) : fieldSelector
    // TODO: add validation rules ?
    return Form.isMatchingTypeForField(value, field, { allowUndefined: this.allowsUndefinedFields }) 
  }

  static isMatchingTypeForField(value: any, field: FieldInfo, { allowNull = false, allowUndefined = false }: { allowNull?: boolean, allowUndefined?: boolean } = {}) {
    if (value == null && allowNull) {
      return true
    }

    if (value === undefined && allowUndefined) {
      return true
    }

    if (field.cardinality) {
      if (!Array.isArray(value)) {
        if (value != null) {
          return false
        }
      }

      for (const v of value) {
        if (!Form.isMatchingSingleValueForType(v, field.type, field.subType, { allowNull, allowUndefined })) {
          return false
        }
      }

      return true
    } else {
      return Form.isMatchingSingleValueForType(value, field.type, field.subType, { allowNull, allowUndefined })
    }
  }

  static isMatchingSingleValueForType(value: any, type: string, subType: string, { allowNull = false, allowUndefined = false }: { allowNull?: boolean, allowUndefined?: boolean }) {
    if (allowNull && value == null) {
      return true
    } 

    if (allowUndefined && value === undefined) {
      return true
    }

    switch (type) {
      case 'string':
        return (typeof value === 'string')
      case 'number':
        return (typeof value === 'number')
      case 'boolean':
        return (typeof value === 'boolean')
      case 'object':
        switch (subType) {
          case 'ref':
            return 'id' in value &&
                    (value.id === null || typeof(value.id) === 'string') &&
                    'v' in value &&
                    (value.v === null || typeof(value.v) === 'string')
            // TODO: Also check for text ?
          case 'file':
          case 'image':
            return 'dataUrl' in value &&
                    (value.dataUrl === null || typeof(value.dataUrl) === 'string')
            // TODO: Also check for all other members ?
          case 'address':
            return  'city' in value &&
                    (value.city === null || typeof(value.city) === 'string')
            // TODO: Also check for all other members ?
          case 'geolocation':
            return  'lat' in value &&
                    (value.lat === null || typeof(value.lat) === 'number') &&
                    'lon' in value &&
                    (value.lon === null || typeof(value.lon) === 'number')
          case 'duration':
            return  !('days' in value) || (value.days == null || typeof(value.days) === 'number') &&
                    !('hours' in value) || (value.hours == null || typeof(value.hours) === 'number') &&
                    !('milliseconds' in value) || (value.milliseconds == null || typeof(value.milliseconds) === 'number') &&
                    !('minutes' in value) || (value.minutes == null || typeof(value.minutes) === 'number') &&
                    !('months' in value) || (value.months == null || typeof(value.months) === 'number') &&
                    !('quarters' in value) || (value.quarters == null || typeof(value.quarters) === 'number') &&
                    !('seconds' in value) || (value.seconds == null || typeof(value.seconds) === 'number') &&
                    !('weeks' in value) || (value.weeks == null || typeof(value.weeks) === 'number') &&
                    !('years' in value) || (value.years == null || typeof(value.years) === 'number') 
          case 'currency': 
            return  'amount' in value && typeof(value.amount) === 'number' &&
                    'currency' in value && typeof(value.currency) === 'string' &&
                    'precision' in value && typeof(value.precision) === 'number'
          default:
            throw new Error(`Unknown subtype ${subType}`)
        }
      default:
        throw new Error(`Unknown type ${type}`)
    }
  }

  validateDocumentData(data: Types.DocumentData) {
    const validationErrors = {}

    const conditions = this.evaluateConditions(data)

    for (const field of this.fields) {
      const error = this.validateDocumentDataField(data, field.name, conditions)
      if (error) {
        Object.assign(validationErrors, {
          [field.name]: error
        })
      }
    }
    
    if (Object.keys(validationErrors).length) {
      return validationErrors
    }
    
    return null
  }
  
  public validateDocumentDataField(data: Types.DocumentData, fieldName: string, conditions?) {
    const fieldInfo = this.getField(fieldName)

    if (!fieldInfo) {
      return null
    }

    const placeholderFieldInfo = this.getPlaceholder(fieldName)

    const validationErrors: any = {}
    const value = data[fieldName]

    if (fieldInfo.required) {
      const allConditions = conditions || this.evaluateConditions(data)

      const isError = fieldHasRequireError(fieldInfo, placeholderFieldInfo, value, allConditions)
      
      if (isError) {
        validationErrors.required = `${placeholderFieldInfo && placeholderFieldInfo.label || fieldName} is required`
      }
    }
    
    if (Object.keys(validationErrors).length) {
      return validationErrors
    }
    
    return null
  }

  public static isFileField(fieldInfo) {
    return (fieldInfo.type === 'object' && (fieldInfo.subType === 'file' || fieldInfo.subType === 'image'))
  }

  public static isMultiField(fieldInfo) {
    return (fieldInfo.cardinality != null)
  }

  public get id() {
    return this.document.id
  }

  public get a() {
    return this.document.a
  }

  public get v() {
    return this.document.v
  }

  public get previous() {
    return this.document.previous
  }

  public get data() {
    return this.document.data
  }

  public get name() {
    return <string>this.document.data.name
  }

  public get allowedChildrenForms(): Reference[] {
    if (this.document.data.allowedChildrenForms) {
      return <Reference[]> this.document.data.allowedChildrenForms
    }
    return []
  }

  private get _definition() {
    return <string>this.document.data.definition
  }

  private _ensureInfo() {
    if (this._info === null) {
      this._info = parse(this._definition)
      this._fields = Object.values(this._info.fields)

      if (this._info.placeholders[0]?.index == null) { 
        console.error('No index in placeholder')
      }

      Object.freeze(this._info)
      Object.freeze(this._fields)
    }
  }

  get rootSection() {
    this._ensureInfo()
    
    if (this._rootSection === null) {
      this._rootSection = new FormSection(this._info.sections[0], this._info)
    }

    return this._rootSection
  }

  get info() {
    this._ensureInfo()
    return this._info
  }

  get fields() {
    this._ensureInfo()
    return this._fields
  }

  getField(name: string) {
    for (const field of this.fields) {
      if (field.name === name) {
        return field
      }
    }

    return null
  }

  getTaggedSections(matchers: TagMatch[], options?: TagMatchOptions ): number[] {
    if (!matchers) {
      throw new Error('matchers is required')
    }
    
    const rootSection = this.info.sections[0]
    
    const self = this

    function fieldMatches(field: ParsedField) {
      return self._fieldTagsMatches(field, matchers, options)
    }

    const undefinedSections = []
    this._checkForMatchingContent(rootSection, null, fieldMatches, undefinedSections)
    return undefinedSections.sort()
  }


  isTaggedSection(document: Types.Document, matchers: TagMatch[], sectionIndex: number, options?: TagMatchOptions): boolean 
  isTaggedSection(document: Types.Document, matchers: TagMatch[], options?: TagMatchOptions): boolean 
  isTaggedSection(document: Types.Document, matchers: TagMatch[], param3: number|TagMatchOptions, param4?: TagMatchOptions): boolean {
    const sectionIndex = typeof(param3) === 'number' ? param3 : 0
    const options = typeof(param3) === 'number' ? param4 : param3

    const section = this.info.sections[sectionIndex]

    if (!section) {
      throw new Error(`Section ${sectionIndex} does not exist in form ${this.id} (${this.name})`)
    }

    const self = this

    function fieldMatches(field: ParsedField) {
      return self._fieldTagsMatches(field, matchers, options)
    }

    const onlyTaggedContent = this._checkForMatchingContent(section, document, fieldMatches)
    return onlyTaggedContent
  }


  /*
   * Return the indices of the sections
   * that are filled with placeholders only 
   * referencing fields that are set to undefined
   * in the specified document.
   * 
   * @param document The document to check
   * @return The indices of the sections that are undefined
   * 
   * This is a recursive check through all sections.
   * A section is undefined if all fields directly and indirectly
   * referenced inside it via placeholders are undefined.
   * 
   */
  getUndefinedSections(document: Types.Document): number[] {
    const rootSection = this.info.sections[0]
    
    const undefinedSections = []
    this._checkForMatchingContent(rootSection, document, (_, value) => value === undefined, undefinedSections)
    return undefinedSections.sort()
  }

  isUndefinedSection(document: Types.Document, sectionIndex: number): boolean {
    const section = this.info.sections[sectionIndex]

    if (!section) {
      throw new Error(`Section ${sectionIndex} does not exist in form ${this.id} (${this.name})`)
    }

    const onlyUndefinedContent = this._checkForMatchingContent(section, document, (_, value) => value === undefined)
    return onlyUndefinedContent
  }

  isSectionDefaultValues(document: Types.Document, sectionIndex: number): boolean {
    const section = this.info.sections[sectionIndex]

    if (!section) {
      throw new Error(`Section ${sectionIndex} does not exist in form ${this.id} (${this.name})`)
    }

    const onlyDefaultValues = this._checkForMatchingContent(section, document, (fieldInfo, value) => this._isDefaultValue(fieldInfo.defaultValue, value))
    return onlyDefaultValues
  }

  isFieldDefaultValue(fieldName: string): boolean {
    const field = this.getField(fieldName)

    if (!field) {
      throw new Error(`Field ${fieldName} is not defined in form ${this.id}`)
    }

    return this._isDefaultValue(field.defaultValue, this.document.data[fieldName])
  }

  _isDefaultValue(defaultValue: any, fieldValue: any) {
    if (typeof defaultValue === 'object') {
      if (typeof fieldValue !== 'object') {
        return false
      }

      for (const key in defaultValue) {
        if (!this._isDefaultValue(defaultValue[key], fieldValue[key])) {
          return false
        }
      }

      return true
    } else if (typeof Array.isArray(defaultValue) ) {
      if (!Array.isArray(fieldValue)) {
        return false
      }

      if (defaultValue.length !== fieldValue.length) {
        return false
      }

      for (let i = 0; i < defaultValue.length; i++) {
        if (!this._isDefaultValue(defaultValue[i], fieldValue[i])) {
          return false
        }
      }

      return true
    } else {
      return defaultValue === fieldValue
    }
  }

  /**
   * Recursively checks for sections where all placeholders match a condition.
   * 
   * A section is considered matching if all fields directly and indirectly
   * referenced inside it via placeholders match the condition.
   * 
   * If a section matches, its index is added to the matchingSections array.
   * If a section has some matching content (directly or indirectly), the method returns true.
   * 
   * By default, sections without any fields are considered non matching unless they 
   * contain only sections which are matching. 
   * 
   * @private
   * 
   * @param section - The section to check for undefined content.
   * @param document - The document containing the parsed form sections.
   * @param matchingSections - An array to store the indices of matching sections.
   * @returns True if the section has only undefined content, false otherwise.
   */
  private _checkForMatchingContent(section: ParsedFormSection, document: Types.Document, condition: (fieldInfo: ParsedField, fieldValue: any) => boolean,  matchingSections: number[] = null) {
    let someSubsectionsHaveContent = false

    for (const directSubSection of section.directSubsections) {
      const sectionOnlyMatchingContent = this._checkForMatchingContent(directSubSection, document, condition, matchingSections)

      if (!sectionOnlyMatchingContent) {
        someSubsectionsHaveContent = true
      }
    }

    // If this section has subsections with content, it is not undefined
    if (someSubsectionsHaveContent) {
      return false
    }
    
    const fieldControlPlaceholders = section.placeholders.filter(placeholder => placeholder.field != null) 

    const hasFields = (fieldControlPlaceholders.length > 0)
    
    let matches = false

    if (!hasFields) {
      const hasSubsections = (section.directSubsections.length > 0)

      // If the section has no fields and no subsections defined
      // it means it is static content. Leave it in and mark it as defined.
      if (!hasSubsections) {
        return false
      }

      // If the section has no fields but has subsections which are
      // all filtered out (see above), it is undefined.
      matches = true
    }

    if (!matches) {
      // If the section has fields, check if they are all undefined
      const allFieldsMatch = this._allFieldsReferencedViaPlaceholdersMatch(section, document, condition)
      matches = allFieldsMatch
    }

    if (matches) {
      matchingSections?.push(section.index)  
    }

    return matches
  }

  private _allFieldsReferencedViaPlaceholdersMatch(section: ParsedFormSection, document: Types.Document, condition: (fieldInfo: ParsedField, fieldValue: any) => boolean) {
    for (const placeholder of section.placeholders) {
      if (placeholder.field == null) {
        continue
      }

      const fieldName = placeholder.field.name
      const field = this.getField(fieldName)

      if (field == null) {
        console.warn('Field not found', fieldName, placeholder, 'section index', section.index)
      }
      
      const match = condition(field, document?.data[fieldName])

      if (!match) {
        return false
      }
    }
    
    return true
  }

  isUndefinedField(document: Types.Document, fieldName: string) {
    const field = this.getField(fieldName)

    if (!field) {
      throw new Error(`Field ${fieldName} is not defined in form ${this.id}`)
    }

    return document.data[fieldName] === undefined
  }

  *enumerateAllFieldsInSection(sectionIndex: number, { skipSubsections }: { skipSubsections?: boolean } = {}) {
    const section = this.info.sections[sectionIndex]

    if (!section) {
      throw new Error(`Section ${sectionIndex} is not defined in form ${this.id}`)
    }

    yield *this._enumerateDirectFieldsInSection(section)
    
    if (skipSubsections) {
      return
    }

    for (const { index } of section.directSubsections) {
      yield *this.enumerateAllFieldsInSection(index)
    }
  }

  private *_enumerateDirectFieldsInSection(section: ParsedFormSection) {
    if (!section.placeholders) {
      return 
    }

    for (const placeholder of section.placeholders) {
      const fieldName = placeholder.field?.name

      if (fieldName) {
        const field = this.getField(fieldName)
        yield { 
          ...field,
          label: placeholder.label,
          placeholder,
        }
      }
    }
  }

  isTaggedField(field: string|FieldInfo, matchers: TagMatch[], options: TagMatchOptions = {}) {
    if (!field) {
      throw new Error('Field is required')
    }

    if (!matchers) {
      throw new Error('Matchers are required')
    }

    if (typeof field === 'string') {
      field = this.getField(field)
    
      if (!field) {
        throw new Error(`Field ${field} is not defined in form ${this.id}`)
      }
    }

    return this._fieldTagsMatches(field, matchers, options)
  }
  
  getTaggedFields(matchers: TagMatch[], sectionIndex?: number): string[]
  getTaggedFields(matchers: TagMatch[], options?: TagMatchOptions): string[] 
  getTaggedFields(matchers: TagMatch[], param2?: number|TagMatchOptions, param3?: TagMatchOptions): string[] {
    const sectionIndex = (typeof param2 === 'number') ? param2 : undefined
    const options = (typeof param2 === 'object') ? param2 : param3

    const self = this
    
    function fieldMatches(field: ParsedField) {
      return self._fieldTagsMatches(field, matchers, options)
    }

    return this._findDocumentFields(null, fieldMatches, sectionIndex)
  }

  private _fieldTagsMatches(field: ParsedField, matchers: TagMatch[], options: TagMatchOptions = {}) {
    if (!field) {
      throw new Error('Field is required')
    }

    if (matchers.length === 0) {
      return true
    }

    if (!field.tags) {
      return false
    }

    const DEFAULT_FIELD_MATCH_OPTIONS = { operation: 'and' }
    const { operation: operation } = { ...DEFAULT_FIELD_MATCH_OPTIONS, ...options }
    
    const defaultResult = (operation === 'and') ? true : false

    for (const matcher of matchers) {
      // extract tag match and value match from matcher 
      const matchInfo = this._extractTagMatchingInfo(matcher)
      const matches = Object.entries(field.tags).some(([tagName, tagValue]) => this._tagMatchesTagMatchInfo(tagName, tagValue, matchInfo))
      
      if (operation === 'and' && !matches) {
        return false
      }

      if (operation === 'or' && matches) {
        return true
      }
    }

    return defaultResult
  }

  _tagMatchesTagMatchInfo(tagName: string, tagValue: ParsedTag, matchInfo: TagMatchInfo) {
    const { name, value, matchName, matchValue } = matchInfo

    if (name && tagName !== name) {
      return false
    }

    if (value && tagValue !== value) {
      return false
    }

    if (matchName && !matchName.test(tagName)) {
      return false
    }

    if (matchValue) {
      if (typeof tagValue === 'boolean') {
        return false
      }

      if (!matchValue.test(tagValue)) {
        return false
      }
    }

    return true
  }

  _extractTagMatchingInfo(matcher: TagMatch) {
    // if matcher is regexp
    if (matcher instanceof RegExp) {
      return { matchName: matcher }
    } else if (typeof matcher === 'string') {
      return { name: matcher}
    } else if (typeof matcher === 'object') {
      let matchInfo: TagMatchInfo = {}

      if (typeof matcher.name === 'string') {
        matchInfo.name = matcher.name
      } else if (matcher.name instanceof RegExp) {
        matchInfo.matchName = matcher.name
      }

      if (typeof matcher.value === 'string') {
        matchInfo.value = matcher.value
      } else if (matcher.value instanceof RegExp) {
        matchInfo.matchValue = matcher.value
      }

      return matchInfo 
    } else {
      throw new Error(`Invalid tag matcher ${JSON.stringify(matcher)}`)
    }
  }

  getUndefinedFields(document: Types.Document, sectionIndex?: number) {
    return this._findDocumentFields(document, (_, value) => value === undefined, sectionIndex)
  }

  private _findDocumentFields(document: Types.Document, condition: (field: ParsedField, value: any) => boolean, sectionIndex?: number) {
    const matching: string[] = []
    
    if (document) {
      if (document.f.id !== this.id) {
        throw new Error(`Document form ${document.f.id} is not this form`)
      }

      if (document.f.v !== this.v) {
        throw new Error(`Document form ${document.f.id} version ${document.f.v} is not this form version ${this.v}`)
      }
    }

    let fields: [string, FieldInfo, any][] = []

    if (sectionIndex) {
      fields = [...this.enumerateAllFieldsInSection(sectionIndex)].map(field => [field.name, field, document?.data[field.name]])
    } else {
      fields = Array.from(this._fields.values()).map(field => [field.name, field, document?.data[field.name]])
    }

    for (const [fieldName, fieldInfo, fieldValue] of fields) {
      if (!fieldInfo) {
        throw new Error(`Document field ${fieldName} is not defined in form ${this.id}`)
      }
      
      if (condition(fieldInfo, fieldValue)) {
        matching.push(fieldName)
      }      
    }
    
    return matching
  }

  getPlaceholders({ includeFieldDeclarations: includePureFieldDeclarations = false } = {}): ParsedPlaceholder[] {
    if (includePureFieldDeclarations) {
      return this.info.placeholders
    } else {
      return this.info.placeholders.filter(p => !(p.field == null && p.control == null))
    }
  }

  getPlaceholder(fieldName: string): ParsedPlaceholder {
    return this.info.placeholders.find(p => p.field && p.field.name === fieldName)
  }
  
  getPlaceholderControlParams(placeholderIndexOrName: string|number) {
    let placeholder

    if (typeof placeholderIndexOrName === 'number') {
      placeholder = this.info.placeholders[placeholderIndexOrName]
    } else {
      placeholder = this.getPlaceholder(placeholderIndexOrName)
    }

    if (!placeholder?.control?.parameters) {
      return null
    }
    
    return toFormControlParameters(placeholder.control.parameters)
  }

  getFieldControlParams(fieldName) {
    const placeholderFieldInfo = this.getPlaceholder(fieldName)

    if (placeholderFieldInfo && placeholderFieldInfo.control && placeholderFieldInfo.control.parameters) {
      return this._camelCaseParameters(placeholderFieldInfo.control.parameters)
    }

    return null
  }
  
  private _camelCaseParameters(parameters) {
    const normalizedParams = {}

    for (const key in parameters) {
      const camelCasedKey = camelCase(key)
      const value = parameters[key].value

      if (value === 'true') {
        normalizedParams[camelCasedKey] = true
        continue
      }

      if (value === 'false') {
        normalizedParams[camelCasedKey] = false
        continue
      }

      normalizedParams[camelCasedKey] = value
    }

    return normalizedParams
  }

  hasField(name: string): boolean {
    return !!this.getField(name)
  }

  hasCalculations(): boolean {
    const info = this.info

    if (Object.keys(info.conditions).length) {
      return true
    }

    for (const fieldName in info.fields) {
      if (this.fieldHasFormula(fieldName)) {
        return true
      }
    }

    return false
  }

  hasFormatting(): boolean {
    const info = this.info

    for (const fieldName in info.fields) {
      if (this.fieldHasFormatting(fieldName)) {
        return true
      }
    }

    return false
  }

  hasOptionalFields({ expressionsOnly = false } = {}): boolean {
    if (!this.allowsUndefinedFields) {
      return false
    }
    
    if (expressionsOnly) {
      return this.fields.some(f => typeof f.tags?.optional === 'string')
    } else {
      return this.fields.some(f => f.tags?.optional)
    }
  }

  getFormController() {
    // if (!this.document.data.functions)
    //   return null

    if (!this._formController) {
      const codeGroup = 'forms'
      const name = `${this.name}(${this.v})`
      const functions = this.document.data.functions ? this.document.data.functions.toString() : ''

      const controllerClassCode = `class Controller{\n`
                                + `${this.indentCode(functions)}\n\n`
                                + `${this.indentCode(this.createConditionsCode())}\n\n`
                                + `${this.indentCode(this.createFormulaCode())}\n\n`
                                + `${this.indentCode(this.createFormattingCode())}\n\n`
                                + `${this.indentCode(this.createOptionalFieldsCode())}\n\n`
                                + `}`
      
      const variablesToExpose = []
      const globals = []

      const FormControllerClass = createControllerClass(codeGroup, name, controllerClassCode, variablesToExpose, globals)
      this._formController = new FormControllerClass([])
    }

    return this._formController
  }

  indentCode(code) {
    return '  ' + code.split('\n').join('\n  ')
  }

  createConditionsCode() {
    let code = ''

    for (const conditionId in this.info.conditions ) {
      const conditionExpression = this.info.conditions[conditionId]
      code += this.createFormulaFunctionCode(conditionId, conditionExpression)
    }

    return code
  }

  createFormulaCode() {
    let code = ''

    const fields = this.fields.filter(f => f.settings && f.settings.formula)

    for (const field of fields) {
      code += this.createFormulaFunctionCode(field.name, field.settings.formula)
    }

    return code
  }

  createFormulaFunctionCode(name, formulaExpression) {
    const dataFieldsAsParameters = Object.keys(this.info.fields).map(key => this.escapeReservedWord(key))
    const allParameters = [...dataFieldsAsParameters, 'functions', 'Math', 'DateTime', 'DateTimeDuration']

    const code = `_formula_${name}(${allParameters.join(', ')}){\n`
               + `  return ${formulaExpression}\n`
               + `}\n\n`

    return code
  }

  createFormattingCode() {
    let code = ''

    const fields = this.fields.filter(f => f.tags?.format)

    for (const field of fields ) {
      code += this.createFormattingFunctionCode(field.name, field.tags.format)
    }

    return code
  }

  createOptionalFieldsCode() {
    if (!this.allowsUndefinedFields) {
      return ''
    }
    
    let code = ''
    
    const fields = this.fields.filter(f => typeof f.tags?.optional === 'string')

    for (const field of fields) {
      code += this.createOptionalFieldFunctionCode(field.name, field.tags.optional)
    }

    return code
  }

  createFormattingFunctionCode(name, formattingExpression) {
    const field = this.getField(name)

    if (field.type !== 'string') {
      console.warn(`Field ${name} is not a string field and cannot be formatted`)
      return 
    }

    const dataFieldsAsParameters = Object.keys(this.info.fields).map(key => this.escapeReservedWord(key))
    const allParameters = [...dataFieldsAsParameters, 'functions', 'Math', 'DateTime', 'DateTimeDuration', 'builtInFormats']

    const individualExpressions = formattingExpression.split(',').map(e => e.trim())
    const steps = individualExpressions.map(exp => {
      if (exp.startsWith('functions.')) {
        return `current = ${exp}`
      } else {
        const paramStart = exp.indexOf('(')

        if (paramStart === -1) {
          const functionName = exp
      
          if (isKnownFieldFormatterFunction(functionName)) {
            return `current = builtInFormats.${functionName}(current)`
          } else {
            console.warn(`Unknown formatting function ${functionName} in field ${name}`)
            return ``
          }
        } else {
          const functionName = exp.substring(0, paramStart)
          
          if (isKnownFieldFormatterFunction(functionName)) {
            const parameters = exp.substring(paramStart + 1, exp.length - 1)
            return `current = builtInFormats.${functionName}(current, ${parameters})`
          } else {
            console.warn(`Unknown formatting function ${functionName} in field ${name}`)
            return ``
          }
        }
      }
    })

    const code = `_format_${name}(${allParameters.join(', ')}){\n`
               + `  let current = ${name};\n`
              +  `  ${steps.join(';\n')}\n`
               + `  return current;\n`
               + `}\n\n`

    return code
  }

  createOptionalFieldFunctionCode(name, requirementExpression) {
    const dataFieldsAsParameters = Object.keys(this.info.fields).map(key => this.escapeReservedWord(key))
    const allParameters = [...dataFieldsAsParameters, 'functions', 'Math', 'DateTime', 'DateTimeDuration']

    const code = `_optional_${name}(${allParameters.join(', ')}){\n`
               + `  return ${requirementExpression}\n`
               + `}\n\n`

    return code
  }
  
  escapeReservedWord(word) {
    const reservedWords = ['abstract', 'arguments', 'await', 'async', 'boolean', 'break', 'byte', 'case', 'catch', 'char',
                           'class', 'const', 'continue', 'debugger', 'default', 'delete', 'do', 'double', 'else',
                           'enum', 'eval', 'export', 'extends', 'false', 'final', 'finally', 'float', 'for',
                           'function', 'goto', 'if', 'implements', 'import', 'in', 'instanceof', 'int', 'interface',
                           'let', 'long', 'native', 'new', 'null', 'package', 'private', 'protected', 'public',
                           'return', 'short', 'static', 'super', 'switch', 'synchronized', 'this', 'throw', 'throws',
                           'transient', 'true', 'try', 'typeof', 'var', 'void', 'volatile', 'while', 'with', 'yield']

    reservedWords.push(...['functions', 'Math', 'DateTime', 'DateTimeDuration'])                       
    
    return (reservedWords.indexOf(word) >= 0 ? `$${word}` : word)
  }

  evaluateFormula(formulaName, data) {
    const ctrl = this.getFormController()
    const dataFieldsAsParameters = Object.keys(this.info.fields)
    const fields = dataFieldsAsParameters.map( (fieldName) => data[fieldName] )
    const parameters = [...fields, ctrl, Math, DateTime, DateTimeDuration]

    const formulaFunction = ctrl[`_formula_${formulaName}`]
    return formulaFunction.apply(ctrl, parameters)
  }

  evaluateConditions(data: Types.DocumentData): FormConditionResults {
    const result: FormConditionResults = {}

    for (const conditionId in this.info.conditions ) {
      result[conditionId] = !!(this.evaluateFormula(conditionId, data))
    }

    return result
  }  

  applyOptionalFieldRules(data: Types.DocumentData): FormOptionalFieldResults|null {
    if (!this.allowsUndefinedFields) {
      return null
    }

    let changed = false
    
    const results = {}
    
    for (const field of this.fields) {
      if (typeof field.tags?.optional === 'string') {
        const shouldBeUndefined = !this.applyOptionalFieldRule(field.name, data)
        results[field.name] = shouldBeUndefined

        if (shouldBeUndefined) {
          if (data[field.name] !== undefined) {
            data[field.name] = undefined
            changed = true
          }
        } else {
          if (data[field.name] === undefined) {
            data[field.name] = this.createDefaultFieldValue(field.name)
            changed = true
          }
        }
      }
    }

    if (!changed) {
      return null
    } else {
      return results
    }
  }

  applyFormatting(data: Types.DocumentData): FormFormattingResults|null {
    let changed = false
    
    const results = {}
    
    for (const field of this.fields) {
      if (field.subType === 'text' && field.tags?.format) {
        const previous = data[field.name]
        data[field.name] = this.applyFieldFormatting(field.name, data)

        if (previous !== data[field.name]) {
          results[field.name] = true
          changed = true
        }
      }
    }

    if (!changed) {
      return null
    } else {
      return results
    }
  }
  
  applyFieldFormatting(fieldName: string, data: Types.DocumentData) {
    const ctrl = this.getFormController()
    const dataFieldsAsParameters = Object.keys(this.info.fields)
    const fields = dataFieldsAsParameters.map( (fieldName) => data[fieldName] )
    // const allParameters = [...dataFieldsAsParameters, 'functions', 'Math', 'DateTime', 'DateTimeDuration', 'builtInFormats']
    const parameters = [...fields, ctrl, Math, DateTime, DateTimeDuration, BUILT_IN_FORMATTER]
    const formulaFunction = ctrl[`_format_${fieldName}`]
    return formulaFunction.apply(ctrl, parameters)
  }

  applyOptionalFieldRule(fieldName: string, data: Types.DocumentData): boolean {
    const ctrl = this.getFormController()
    const dataFieldsAsParameters = Object.keys(this.info.fields)
    const fields = dataFieldsAsParameters.map( (fieldName) => data[fieldName] )
    // const allParameters = [...dataFieldsAsParameters, 'functions', 'Math', 'DateTime', 'DateTimeDuration', 'builtInFormats']
    const parameters = [...fields, ctrl, Math, DateTime, DateTimeDuration, BUILT_IN_FORMATTER]
    const optionalFunction = ctrl[`_optional_${fieldName}`]
    return optionalFunction.apply(ctrl, parameters)
  }
  
  shouldApplyStyleToPlaceholder(placeholder: ParsedPlaceholder, styleName: string, evaluatedConditions: any): boolean {
    return _shouldApplyStyleToPlaceholder(placeholder, styleName, evaluatedConditions)
  }

  shouldApplyStyleToSection(section, styleName: string, evaluatedConditions: any): boolean {
    // currently compatible....
    return _shouldApplyStyleToPlaceholder(section, styleName, evaluatedConditions)
  }
  
  updateCalculatedFields(data: any): boolean {
    const MAX_ITERATIONS = 20
    const fieldsWithFormulas = this.getFieldsWithFormulas()
    const ctrl = this.getFormController()

    for (let iteration = 0; iteration < MAX_ITERATIONS; iteration += 1) {
      let didChangeDataInThisIteration = false

      for (const field of fieldsWithFormulas) {    
        const previousFieldValue = data[field.name]
        const newFieldValue = this.evaluateFormula(field.name, data)

        if (fieldHasChanged(field, previousFieldValue, newFieldValue) ) {
          data[field.name] = newFieldValue
          didChangeDataInThisIteration = true
        }
      }

      if (!didChangeDataInThisIteration) {
        return (iteration > 0)
      }
    }

    console.warn('Too many iterations calculating formulas.')
    return true
  }

  private getFieldsWithFormulas() {
    return this.fields.filter(f => f.settings && f.settings.formula)
  }

  private fieldHasFormula(fieldName: string) {
    const field = this.getField(fieldName)
    return field?.settings?.formula
  }

  private fieldHasFormatting(fieldName: string) {
    const field = this.getField(fieldName)
    return typeof field?.tags?.format === 'string'
  }

  freezeData(data) {
    Object.freeze(data)

    // Note: Don't use .fields since this
    // would force parsing of forms on import
    //
    // If they exist nice, otherwise just walk through
    // using vanilla JS
    //
    if (this._fields) {
      for (const fieldInfo of this._fields) {
        if (fieldInfo.type === 'object') {
          Object.freeze(data[fieldInfo.name])
        }
      }
    } else {
      for (const dataPropertyValue of Object.values(data)) {
        Object.freeze(dataPropertyValue)
      }
    }
  }

  cloneDocumentData(data, fieldsNotToClone?: Array<string>) {
    const clone = {}

    for (const fieldInfo of this.fields) {
      const cloneFromDefault = !!(fieldsNotToClone && fieldsNotToClone.includes(fieldInfo.name))
      
      const fieldData = cloneFromDefault ? null : data[fieldInfo.name]

      clone[fieldInfo.name] = this.cloneFieldValue(fieldData, fieldInfo.name, cloneFromDefault)
    }

    return clone
  }

  cloneDocumentField(data, fieldName, cloneFromDefault?: boolean) {
    const value = data[fieldName]
    return this.cloneFieldValue(value, fieldName, cloneFromDefault)
  }
  
  cloneFieldValue(value, fieldName, cloneFromDefault?: boolean) {
    if (!cloneFromDefault && typeof value === 'undefined') { 
      return undefined
    }

    let normalizedValue = value

    if (cloneFromDefault === true && value == null) {
      if (this.info.fields[fieldName].cardinality) {
        normalizedValue = []
      } else {
        normalizedValue = this.info.fields[fieldName].defaultValue
      }
    }
    
    return Form.cloneFieldValue(this.info.fields[fieldName], normalizedValue)
  }

  createDefaultFieldValue(fieldName: string) {
    const fieldInfo = this.getField(fieldName)

    if (this.info.fields[fieldName].cardinality) {
      return []
    } else {
      return Form.cloneSingleFieldValue(fieldInfo, fieldInfo.defaultValue)
    }
  }

  copyFieldsFromDifferentForm(data, previousData, previousForm: Form) {
    for (const field of this._fields) {
      const previousFieldDefinition = previousForm.getField(field.name)

      data[field.name] = copyFieldFromDifferentForm(data[field.name], field, previousData[field.name], previousFieldDefinition)
    }
  }

  static isRefField(fieldInfo) {
    return fieldInfo && fieldInfo.type === 'object' && fieldInfo.subType === 'ref'
  }

  static cloneFieldValue(fieldInfo, field) {
    if (fieldInfo.cardinality) {
      return cloneArrayOfValues(fieldInfo, field)
    } else {
      return cloneSingleField(fieldInfo, field)
    }
  }

  static cloneSingleFieldValue(fieldInfo, field) {
    return cloneSingleField(fieldInfo, field)
  }

  hasChanged(fromData, toData) {
    const info = this.info

    for (const fieldName in info.fields) {
      const fieldInfo = info.fields[fieldName]

      if (!fieldInfo) {
        continue
      }

      if (Form.hasFieldChanged(fieldInfo, fromData[fieldName], toData[fieldName])) {
        return true
      }
    }

    return false
  }

  hasFieldChanged(fieldInfo, from, to, options?) {
    return Form.hasFieldChanged(fieldInfo, from, to, options)
  }
  
  static hasSingleValueChanged(fieldInfo, from, to) {
    return singleValueHasChanged(fieldInfo, from, to)
  }

  static hasFieldChanged(fieldInfo, from, to, options?) {
    if (fieldHasChanged(fieldInfo, from, to, options)) {
      return true
    } else {
      return false
    }
  }
}

function fieldHasChanged(fieldInfo, oldFieldValue, newFieldValue, options?) {
  if (fieldInfo.cardinality) {
    return arrayOfValuesHasChanged(fieldInfo, oldFieldValue, newFieldValue, options)
  } else {
    return singleValueHasChanged(fieldInfo, oldFieldValue, newFieldValue, options)
  }
}

function arrayOfValuesHasChanged(fieldInfo, oldField, newField, options?) {
  if (oldField == null && newField == null) {
    return false
  }

  if (oldField == null && newField != null) {
    return true
  }

  if (oldField != null && newField == null) {
    return true
  }

  if (oldField.length !== newField.length) {
    return true
  }

  for (let i = 0; i < oldField.length; i += 1) {
    if (singleValueHasChanged(fieldInfo, oldField[i], newField[i], options)) {
      return true
    }
  }
  return false
}

// TODO: Convert types, subtypes, cardinality
function copyFieldsFromDifferentForm(fieldValue, fieldInfo, oldFieldValue?, oldFieldInfo?) {
  if (!oldFieldInfo) {
    return fieldValue
  }

  if (fieldInfo.type === oldFieldInfo.type &&
      fieldInfo.subType === oldFieldInfo.subType) {
    if (fieldInfo.cardinality && oldFieldInfo.cardinality) {
      if (fieldInfo.cardinality.min === oldFieldInfo.cardinality.min &&
          fieldInfo.cardinality.max === oldFieldInfo.cardinality.max) {
          return oldFieldValue
        }
    } else if (!fieldInfo.cardinality && !oldFieldInfo.cardinality) {
      return oldFieldValue
    } else if (fieldInfo.cardinality && !oldFieldInfo.cardinality) {
      return [ oldFieldValue ]
    } else if (!fieldInfo.cardinality && oldFieldInfo.cardinality && oldFieldValue[0]) {
      return oldFieldValue[0]
    }
  }

  return fieldValue
}

function copyFieldFromDifferentForm(fieldValue, fieldInfo, otherFieldValue?, otherFieldInfo?) {
  if (!otherFieldInfo) {
    return fieldValue
  }

  if (fieldInfo.cardinality && otherFieldInfo.cardinality) {
    const max = fieldInfo.cardinality.max === 'n' ? 1000000 :  parseInt(fieldInfo.cardinality.max)
    fieldValue.length = 0

    if (otherFieldValue === undefined) {
      fieldValue = undefined
      return fieldValue
    }

    for (const v of otherFieldValue) {
      if (fieldValue.length >= max) {
        break
      }
      fieldValue.push(tryConvertSingleField(fieldInfo, v, otherFieldInfo))
    }
    return fieldValue
  }
  else if (fieldInfo.cardinality && !otherFieldInfo.cardinality) {
    
    fieldValue.length = 0
    const convertedValue = tryConvertSingleField(fieldInfo, otherFieldValue, otherFieldInfo)

    if (singleValueHasChanged(fieldInfo, fieldInfo.defaultValue, convertedValue)) {
      fieldValue.push(convertedValue)
    }
    return fieldValue  
  }
  else if (otherFieldInfo.cardinality) {
    return tryConvertSingleField(fieldInfo, otherFieldValue[0], otherFieldInfo)
  }
  else {
    return tryConvertSingleField(fieldInfo, otherFieldValue, otherFieldInfo)
  }
}


function tryConvertSingleField(targetFieldInfo, sourceFieldValue, sourceFieldInfo) {
  if (targetFieldInfo.type === sourceFieldInfo.type && targetFieldInfo.subType === sourceFieldInfo.subType) {
    return cloneSingleField(targetFieldInfo, sourceFieldValue)
  }

  if ((sourceFieldValue === null) || (sourceFieldValue === undefined)) {
    return cloneSingleField(targetFieldInfo, null)
  }
  
  if (targetFieldInfo.type === 'string') {
    if (sourceFieldInfo.type === 'string' || sourceFieldInfo.type === 'number' || sourceFieldInfo.type === 'boolean') {
      return sourceFieldValue.toString()
    }
  } 

  if (targetFieldInfo.type === 'number') {
    if (sourceFieldInfo.type === 'number') {
      return sourceFieldValue
    }
    if (sourceFieldInfo.type === 'string') {
      return  parseInt(sourceFieldValue) || 0
    }
    if (sourceFieldInfo.type === 'boolean') {
      return sourceFieldValue ? 1 : 0
    }
  }

  return cloneSingleField(targetFieldInfo, null) 
}


function singleValueHasChanged(fieldInfo, oldFieldValue, newFieldValue, options?) {
   switch ( fieldInfo.type ) {
    case 'string':
    case 'number':
    case 'boolean':
      return oldFieldValue !== newFieldValue
    case 'object':
      return objectFieldHasChanged(fieldInfo, oldFieldValue, newFieldValue, options)
    default:
      log.error(`Do not know how to compare field type ${fieldInfo.type}`)
      return JSON.stringify(oldFieldValue) !== JSON.stringify(newFieldValue)
  }
}

function objectFieldHasChanged(fieldInfo, oldFieldValue, newFieldValue, options?) {
  const strict = options ? options.compareStrict === false ? false : true : true

  if (oldFieldValue == null && newFieldValue != null) {
    return true
  }

  if (oldFieldValue != null && newFieldValue == null) {
    return true
  }

  if (oldFieldValue == null && newFieldValue == null) {
    return false
  }

  switch ( fieldInfo.subType ) {
    case 'ref':
      return (oldFieldValue.id     !== newFieldValue.id ||
              oldFieldValue.v      !== newFieldValue.v  ||
              oldFieldValue.text   !== newFieldValue.text )
    case 'image':
    case 'file':
      let isDifferentLoose = (oldFieldValue.name !== newFieldValue.name ||
              oldFieldValue.size !== newFieldValue.size ||
              oldFieldValue.type !== newFieldValue.type ||
              oldFieldValue.sha1 !== newFieldValue.sha1 || 
              (oldFieldValue.dataUrl      && !oldFieldValue.dataUrl.startsWith('aeppic-local://')      &&
               newFieldValue.dataUrl      && !newFieldValue.dataUrl.startsWith('aeppic-local://')      && (oldFieldValue.dataUrl !== newFieldValue.dataUrl)) ||
              (oldFieldValue.thumbnailUrl && !oldFieldValue.thumbnailUrl.startsWith('aeppic-local://') && 
               newFieldValue.thumbnailUrl && !newFieldValue.thumbnailUrl.startsWith('aeppic-local://') && (oldFieldValue.thumbnailUrl !== newFieldValue.thumbnailUrl)) ||
              (oldFieldValue.iconUrl      && !oldFieldValue.iconUrl.startsWith('aeppic-local://')      && 
               newFieldValue.iconUrl      && !newFieldValue.iconUrl.startsWith('aeppic-local://')      && (oldFieldValue.iconUrl !== newFieldValue.iconUrl)))

      const isDifferentStrict = (oldFieldValue.dataUrl      !== newFieldValue.dataUrl ||
              oldFieldValue.thumbnailUrl !== newFieldValue.thumbnailUrl ||
              oldFieldValue.iconUrl      !== newFieldValue.iconUrl ||
              oldFieldValue.fileInfo.created   !== newFieldValue.fileInfo.created ||
              oldFieldValue.fileInfo.read      !== newFieldValue.fileInfo.read ||
              oldFieldValue.fileInfo.modified  !== newFieldValue.fileInfo.modified ||
              oldFieldValue.fileInfo.imported  !== newFieldValue.fileInfo.imported)

      if (fieldInfo.subType === 'image') {
        isDifferentLoose = isDifferentLoose || ( 
          oldFieldValue.mediaInfo?.width  !== newFieldValue.mediaInfo?.width ||
          oldFieldValue.mediaInfo?.height !== newFieldValue.mediaInfo?.height )
      }
      return !!(strict ? isDifferentStrict || isDifferentLoose : isDifferentLoose)
    case 'address':
      return (oldFieldValue.street        !== newFieldValue.street ||
              oldFieldValue.streetNumber  !== newFieldValue.streetNumber ||
              oldFieldValue.postalCode    !== newFieldValue.postalCode ||
              oldFieldValue.city          !== newFieldValue.city ||
              oldFieldValue.state         !== newFieldValue.state ||
              oldFieldValue.country       !== newFieldValue.country ||
              oldFieldValue.supplement    !== newFieldValue.supplement )
    case 'geolocation':
      return (oldFieldValue.lat  !== newFieldValue.lat ||
              oldFieldValue.lon  !== newFieldValue.lon )
    case 'duration':
      return (
        oldFieldValue.days !== newFieldValue.days ||
        oldFieldValue.hours !== newFieldValue.hours ||
        oldFieldValue.milliseconds !== newFieldValue.milliseconds ||
        oldFieldValue.minutes !== newFieldValue.minutes ||
        oldFieldValue.months !== newFieldValue.months ||
        oldFieldValue.quarters !== newFieldValue.quarters ||
        oldFieldValue.seconds !== newFieldValue.seconds ||
        oldFieldValue.weeks !== newFieldValue.weeks ||
        oldFieldValue.years !== newFieldValue.years)
    case 'currency': 
      return (
        oldFieldValue.amount !== newFieldValue.amount ||
        oldFieldValue.currency !== newFieldValue.currency ||
        oldFieldValue.precision !== newFieldValue.precision)
    default:
      log.error(`Do not know how to compare object field with subType ${fieldInfo.subType}`)
      return JSON.stringify(oldFieldValue) !== JSON.stringify(newFieldValue)
  }
}

function cloneSingleField(fieldInfo, value) {
  switch ( fieldInfo.type ) {
    case 'string':
    case 'number':
    case 'boolean':
      return value
    case 'object':
      return cloneObject(fieldInfo, value)
    default:
      log.error(`Do not know how to clone field type ${fieldInfo.type}`)
      return JSON.parse(JSON.stringify(value))
  }
}

function cloneObject(fieldInfo, fieldValue) {
  if (typeof fieldValue === 'undefined') {
    return undefined
  }

  if (fieldValue == null) {
    fieldValue = fieldInfo.defaultValue
  }

  switch ( fieldInfo.subType ) {
    case 'ref':
      return { id: fieldValue.id, v: fieldValue.v, text: fieldValue.text }
    
    case 'image':
    case 'file':
      const object: any = {
        name: fieldValue.name,
        size: fieldValue.size,
        type: fieldValue.type,
        sha1: fieldValue.sha1,
        dataUrl: fieldValue.dataUrl,
        thumbnailUrl: fieldValue.thumbnailUrl,
        iconUrl: fieldValue.iconUrl,
      }

      if (fieldValue.fileInfo) {
        object.fileInfo = {
          created: fieldValue.fileInfo.created,
          read: fieldValue.fileInfo.read,
          modified: fieldValue.fileInfo.modified,
          imported: fieldValue.fileInfo.imported
        }

        if (fieldInfo.subType === 'image') {
          object.mediaInfo = {
            width: fieldValue.fileInfo.width,
            height: fieldValue.fileInfo.height,
          }
        }
      }

      return object
    case 'address':
      return {
        street: fieldValue.street,
        streetNumber: fieldValue.streetNumber,
        postalCode: fieldValue.postalCode,
        city: fieldValue.city,
        state: fieldValue.state,
        country: fieldValue.country,
        supplement: fieldValue.supplement,
      }
    case 'geolocation':
      return {
        lat: fieldValue.lat,
        lon: fieldValue.lon,
      }
    case 'duration':
      return {
        days: fieldValue.days,
        hours: fieldValue.hours,
        milliseconds: fieldValue.milliseconds,
        minutes: fieldValue.minutes,
        months: fieldValue.months,
        quarters: fieldValue.quarters,
        seconds: fieldValue.seconds,
        weeks: fieldValue.weeks,
        years: fieldValue.years,
      }
    case 'currency': 
      return {
        amount: fieldValue.amount,
        currency: fieldValue.currency,
        precision: fieldValue.precision,
      }
    default:
      log.error(`Do not know how to clone object field with subType ${fieldInfo.subType}`)
      return JSON.parse(JSON.stringify(fieldValue))
  }
}

function cloneArrayOfValues(fieldInfo, values) {
  if (values == null) {
    return []
  }

  const numberOfEntries = values.length
  const clone = new Array(numberOfEntries)

  for (let i = 0; i < numberOfEntries; i += 1) {
    clone[i] = cloneSingleField(fieldInfo, values[i])
  }

  return clone
}

function fieldHasRequireError(fieldInfo, placeholder, value, conditions, { ignoreCardinality } = { ignoreCardinality: false }) {
  // NOTE: only one/first placeholder is checked. This needs to be improved in the future
  if (_shouldApplyStyleToPlaceholder(placeholder, 'hidden', conditions)) {
    return false
  }

  if (value === null || value === undefined) {
    return true
  }
  
  if (!ignoreCardinality && fieldInfo.cardinality) {
    if (!Array.isArray(value) || value.length === 0) {
      return true
    }
    
    return value.some(v => fieldHasRequireError(fieldInfo, placeholder, v, conditions, { ignoreCardinality: true }))
  }
  
  switch (fieldInfo.type) {
    case 'string': {
      switch (fieldInfo.subType) {
        case 'mail':
          return !value || !value.match(EMAIL_REGEX)
      }

      return !value
    }
    case 'number': {
      return Number.isNaN(value) || value === 0
    }
    case 'boolean': {
      return !value
    }
    case 'object':
      switch (fieldInfo.subType) {
        case 'ref':
          return !('id' in value) ||
                  value.id === null || typeof(value.id) !== 'string' || value.id === '' ||
                  !('v' in value) ||
                  value.v === null || typeof(value.v) !== 'string' || value.v === ''
          // TODO: Also check for text ?
        case 'file':
        case 'image':
          return !('dataUrl' in value) ||
                  value.dataUrl === null || typeof(value.dataUrl) !== 'string' || value.dataUrl === ''
          // TODO: Also check for all other members ?
        case 'address':
          return  !('city' in value) ||
                  value.city === null || typeof(value.city) !== 'string' || value.city === ''
          // TODO: Also check for all other members ?
        case 'geolocation':
          return  !('lat' in value) ||
                  value.lat === null || typeof(value.lat) !== 'number' ||
                  !('lon' in value) ||
                  value.lon === null || typeof(value.lon) !== 'number'
        case 'duration':
          const keys = Object.keys(value)

          if (keys.length === 0) {
            return true
          } else {
            const firstMissingValue = keys.find(k => value[k] == null)
            return !!firstMissingValue
          }
        case 'currency': 
          return  !('amount' in value) || typeof(value.amount) !== 'number' || value.amount === 0 ||
                  !('currency' in value) || typeof(value.currency) !== 'string' || value.currency === '' ||
                  !('precision' in value) || typeof(value.precision) !== 'number'
    }
  }
}

function compareForms(a: Form, b: Form): number {
  if (!a.document.t && !b.document.t) {
    return a.document.modified.at.localeCompare(b.document.modified.at)
  }

  if (!a.document.t) {
    return 1
  } else if (!b.document.t) {
    return -1
  } else {
    return a.document.t - b.document.t
  }
}

function _shouldApplyStyleToPlaceholder(placeholder: ParsedPlaceholder, styleName: string, evaluatedConditions: any): boolean {
  const hasTheStyleDefined = placeholder && placeholder.style && placeholder.style[styleName]
  const isConditionalStyle = hasTheStyleDefined && placeholder.style[styleName].condition
  const isConditionMet = !isConditionalStyle || !evaluatedConditions || evaluatedConditions[placeholder.style[styleName].condition.id]

  return !!(hasTheStyleDefined && isConditionMet)
}
