// @generated by Peggy 4.0.3.
//
// https://peggyjs.org/


function peg$subclass(child, parent) {
  function C() { this.constructor = child; }
  C.prototype = parent.prototype;
  child.prototype = new C();
}

function peg$SyntaxError(message, expected, found, location) {
  var self = Error.call(this, message);
  // istanbul ignore next Check is a necessary evil to support older environments
  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(self, peg$SyntaxError.prototype);
  }
  self.expected = expected;
  self.found = found;
  self.location = location;
  self.name = "SyntaxError";
  return self;
}

peg$subclass(peg$SyntaxError, Error);

function peg$padEnd(str, targetLength, padString) {
  padString = padString || " ";
  if (str.length > targetLength) { return str; }
  targetLength -= str.length;
  padString += padString.repeat(targetLength);
  return str + padString.slice(0, targetLength);
}

peg$SyntaxError.prototype.format = function(sources) {
  var str = "Error: " + this.message;
  if (this.location) {
    var src = null;
    var k;
    for (k = 0; k < sources.length; k++) {
      if (sources[k].source === this.location.source) {
        src = sources[k].text.split(/\r\n|\n|\r/g);
        break;
      }
    }
    var s = this.location.start;
    var offset_s = (this.location.source && (typeof this.location.source.offset === "function"))
      ? this.location.source.offset(s)
      : s;
    var loc = this.location.source + ":" + offset_s.line + ":" + offset_s.column;
    if (src) {
      var e = this.location.end;
      var filler = peg$padEnd("", offset_s.line.toString().length, ' ');
      var line = src[s.line - 1];
      var last = s.line === e.line ? e.column : line.length + 1;
      var hatLen = (last - s.column) || 1;
      str += "\n --> " + loc + "\n"
          + filler + " |\n"
          + offset_s.line + " | " + line + "\n"
          + filler + " | " + peg$padEnd("", s.column - 1, ' ')
          + peg$padEnd("", hatLen, "^");
    } else {
      str += "\n at " + loc;
    }
  }
  return str;
};

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
    literal: function(expectation) {
      return "\"" + literalEscape(expectation.text) + "\"";
    },

    class: function(expectation) {
      var escapedParts = expectation.parts.map(function(part) {
        return Array.isArray(part)
          ? classEscape(part[0]) + "-" + classEscape(part[1])
          : classEscape(part);
      });

      return "[" + (expectation.inverted ? "^" : "") + escapedParts.join("") + "]";
    },

    any: function() {
      return "any character";
    },

    end: function() {
      return "end of input";
    },

    other: function(expectation) {
      return expectation.description;
    }
  };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, "\\\\")
      .replace(/"/g,  "\\\"")
      .replace(/\0/g, "\\0")
      .replace(/\t/g, "\\t")
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/[\x00-\x0F]/g,          function(ch) { return "\\x0" + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return "\\x"  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, "\\\\")
      .replace(/\]/g, "\\]")
      .replace(/\^/g, "\\^")
      .replace(/-/g,  "\\-")
      .replace(/\0/g, "\\0")
      .replace(/\t/g, "\\t")
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/[\x00-\x0F]/g,          function(ch) { return "\\x0" + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return "\\x"  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = expected.map(describeExpectation);
    var i, j;

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== undefined ? options : {};

  var peg$FAILED = {};
  var peg$source = options.grammarSource;

  var peg$startRuleFunctions = { Start: peg$parseStart };
  var peg$startRuleFunction = peg$parseStart;

  var peg$c0 = "===";
  var peg$c1 = "---";
  var peg$c2 = "#";
  var peg$c3 = "{";
  var peg$c4 = "}";
  var peg$c5 = "[";
  var peg$c6 = "][";
  var peg$c7 = "]";
  var peg$c8 = "|";
  var peg$c9 = "if";
  var peg$c10 = "||";
  var peg$c11 = "readonly";
  var peg$c12 = "editable";
  var peg$c13 = "hidden";
  var peg$c14 = "hide";
  var peg$c15 = "show";
  var peg$c16 = ":";
  var peg$c17 = "=";
  var peg$c18 = "-";
  var peg$c19 = "<";
  var peg$c20 = ">";
  var peg$c21 = "em";
  var peg$c22 = "px";
  var peg$c23 = "rem";
  var peg$c24 = "*";
  var peg$c25 = "col";
  var peg$c26 = "%";
  var peg$c27 = "\"";
  var peg$c28 = "\\\"";
  var peg$c29 = "'";
  var peg$c30 = "\\'";
  var peg$c31 = "text";
  var peg$c32 = "(";
  var peg$c33 = "password";
  var peg$c34 = "number";
  var peg$c35 = "select";
  var peg$c36 = "switch";
  var peg$c37 = "date";
  var peg$c38 = "datetime";
  var peg$c39 = "mail";
  var peg$c40 = "dayofyear";
  var peg$c41 = "time";
  var peg$c42 = "duration";
  var peg$c43 = "ref";
  var peg$c44 = "file";
  var peg$c45 = "image";
  var peg$c46 = "address";
  var peg$c47 = "currency";
  var peg$c48 = "geolocation";
  var peg$c49 = "n";
  var peg$c50 = ")";
  var peg$c51 = "\t";
  var peg$c52 = "  ";
  var peg$c53 = "\n";
  var peg$c54 = "\r\n";
  var peg$c55 = "\r";

  var peg$r0 = /^[a-zA-Z_]/;
  var peg$r1 = /^[a-zA-Z0-9_]/;
  var peg$r2 = /^[|-}]/;
  var peg$r3 = /^[a-z0-9_\-]/i;
  var peg$r4 = /^[a-z_]/i;
  var peg$r5 = /^[a-z0-9:_\-]/i;
  var peg$r6 = /^[^ \t\n\r'"|}=>]/;
  var peg$r7 = /^[^\n\r"]/;
  var peg$r8 = /^[^\n\r']/;
  var peg$r9 = /^[^\n\r\\""]/;
  var peg$r10 = /^[0-9.,]/;
  var peg$r11 = /^[\t ]/;
  var peg$r12 = /^[\n\r]/;

  var peg$e0 = peg$literalExpectation("===", false);
  var peg$e1 = peg$literalExpectation("---", false);
  var peg$e2 = peg$literalExpectation("#", false);
  var peg$e3 = peg$literalExpectation("{", false);
  var peg$e4 = peg$literalExpectation("}", false);
  var peg$e5 = peg$literalExpectation("[", false);
  var peg$e6 = peg$literalExpectation("][", false);
  var peg$e7 = peg$literalExpectation("]", false);
  var peg$e8 = peg$classExpectation([["a", "z"], ["A", "Z"], "_"], false, false);
  var peg$e9 = peg$classExpectation([["a", "z"], ["A", "Z"], ["0", "9"], "_"], false, false);
  var peg$e10 = peg$literalExpectation("|", false);
  var peg$e11 = peg$literalExpectation("if", false);
  var peg$e12 = peg$literalExpectation("||", false);
  var peg$e13 = peg$literalExpectation("readonly", false);
  var peg$e14 = peg$literalExpectation("editable", false);
  var peg$e15 = peg$literalExpectation("hidden", false);
  var peg$e16 = peg$literalExpectation("hide", false);
  var peg$e17 = peg$literalExpectation("show", false);
  var peg$e18 = peg$classExpectation([["|", "}"]], false, false);
  var peg$e19 = peg$literalExpectation(":", false);
  var peg$e20 = peg$classExpectation([["a", "z"], ["0", "9"], "_", "-"], false, true);
  var peg$e21 = peg$literalExpectation("=", false);
  var peg$e22 = peg$classExpectation([["a", "z"], "_"], false, true);
  var peg$e23 = peg$literalExpectation("-", false);
  var peg$e24 = peg$literalExpectation("<", false);
  var peg$e25 = peg$literalExpectation(">", false);
  var peg$e26 = peg$classExpectation([["a", "z"], ["0", "9"], ":", "_", "-"], false, true);
  var peg$e27 = peg$literalExpectation("em", false);
  var peg$e28 = peg$literalExpectation("px", false);
  var peg$e29 = peg$literalExpectation("rem", false);
  var peg$e30 = peg$literalExpectation("*", false);
  var peg$e31 = peg$literalExpectation("col", false);
  var peg$e32 = peg$literalExpectation("%", false);
  var peg$e33 = peg$classExpectation([" ", "\t", "\n", "\r", "'", "\"", "|", "}", "=", ">"], true, false);
  var peg$e34 = peg$literalExpectation("\"", false);
  var peg$e35 = peg$literalExpectation("\\\"", false);
  var peg$e36 = peg$classExpectation(["\n", "\r", "\""], true, false);
  var peg$e37 = peg$literalExpectation("'", false);
  var peg$e38 = peg$literalExpectation("\\'", false);
  var peg$e39 = peg$classExpectation(["\n", "\r", "'"], true, false);
  var peg$e40 = peg$literalExpectation("Text", true);
  var peg$e41 = peg$literalExpectation("(", false);
  var peg$e42 = peg$literalExpectation("Password", true);
  var peg$e43 = peg$literalExpectation("Number", true);
  var peg$e44 = peg$literalExpectation("Select", true);
  var peg$e45 = peg$literalExpectation("Switch", true);
  var peg$e46 = peg$literalExpectation("Date", true);
  var peg$e47 = peg$literalExpectation("DateTime", true);
  var peg$e48 = peg$literalExpectation("Mail", true);
  var peg$e49 = peg$literalExpectation("DayOfYear", true);
  var peg$e50 = peg$literalExpectation("Time", true);
  var peg$e51 = peg$literalExpectation("Duration", true);
  var peg$e52 = peg$literalExpectation("Ref", true);
  var peg$e53 = peg$literalExpectation("File", true);
  var peg$e54 = peg$literalExpectation("Image", true);
  var peg$e55 = peg$literalExpectation("Address", true);
  var peg$e56 = peg$literalExpectation("Currency", true);
  var peg$e57 = peg$literalExpectation("Geolocation", true);
  var peg$e58 = peg$literalExpectation("n", true);
  var peg$e59 = peg$literalExpectation(")", false);
  var peg$e60 = peg$classExpectation(["\n", "\r", "\\", "\"", "\""], true, false);
  var peg$e61 = peg$classExpectation([["0", "9"], ".", ","], false, false);
  var peg$e62 = peg$anyExpectation();
  var peg$e63 = peg$otherExpectation("End of Block (empty lines)");
  var peg$e64 = peg$otherExpectation("End of Line (return)");
  var peg$e65 = peg$literalExpectation("\t", false);
  var peg$e66 = peg$literalExpectation("  ", false);
  var peg$e67 = peg$otherExpectation("whitespace");
  var peg$e68 = peg$classExpectation(["\t", " "], false, false);
  var peg$e69 = peg$classExpectation(["\n", "\r"], false, false);
  var peg$e70 = peg$otherExpectation("end of line");
  var peg$e71 = peg$literalExpectation("\n", false);
  var peg$e72 = peg$literalExpectation("\r\n", false);
  var peg$e73 = peg$literalExpectation("\r", false);

  var peg$f0 = function(formDefinition) {
      result.definition = text();
      completeSections();
      applyDefaultDirectives();
      
      return result; 
    };
  var peg$f1 = function(rootDirectives) {
      applyDirectives(result.sections[0], rootDirectives);
    };
  var peg$f2 = function(directives) { return directives };
  var peg$f3 = function(ss) {
      addSection(location().start.offset, text().length, ss);
    };
  var peg$f4 = function(td) {
      addTypeDefinition(td.offset, td.length, td.fieldName, td.spec);
    };
  var peg$f5 = function(directives, section) {
      section.directives = directives;
      return section;
    };
  var peg$f6 = function(section) {
      section.directives = [];
      return section;
    };
  var peg$f7 = function(chars) {
      return {
        level: 1,
        title: chars.join('').trim(),
        type: "SETEXT",
        offset: location().start.offset,
        length: text().length
      };
    };
  var peg$f8 = function(chars) {
      return {
        level: 2,
        title: chars.join('').trim(),
        type: "SETEXT",
        offset: location().start.offset,
        length: text().length
      };
    };
  var peg$f9 = function(pounds, title) {
      return {
        level: pounds.length,
        title: title || '',
        type: "ATX",
        offset: location().start.offset,
        length: text().length
      };
    };
  var peg$f10 = function(chars) {
      return chars.join('').trim();
    };
  var peg$f11 = function(fp) {
      //special case: when a block consists of just one field, then the directives
      //apply to the field, not the block
      addPlaceholder(location().start.offset, text().length, fp.directives, fp.field.name, fp.field.label);
      completeCurrentParagraph(location().start.offset, text().length, location().start.offset, []);
      addNextParagraph();
    };
  var peg$f12 = function(directives, block) {
      completeCurrentParagraph(location().start.offset, text().length, block.offset, directives);
      addNextParagraph();
    };
  var peg$f13 = function(block) {
      completeCurrentParagraph(location().start.offset, text().length, location().start.offset, []);
      addNextParagraph();
    };
  var peg$f14 = function() {
      return {
        offset: location().start.offset,
        length: text().length
      }
    };
  var peg$f15 = function(fp) {
      addPlaceholder(location().start.offset, text().length, fp.directives, fp.field.name, fp.field.label)
    };
  var peg$f16 = function(sc) {
      addPlaceholder(sc.offset, sc.length, [sc.controlDirective])
    };
  var peg$f17 = function() {
      if(text().trim().length > 0){
        currentParagraph().hasText = true;
      }
    };
  var peg$f18 = function() { return text(); };
  var peg$f19 = function(cd) {
    return {
      offset: location().start.offset,
      length: text().length,
      controlDirective: cd
    }
  };
  var peg$f20 = function(directives, field) {
      return {
        directives:directives, 
        field:field
      } 
    };
  var peg$f21 = function(field) {
      return {
       directives:[], 
       field:field
      } 
    };
  var peg$f22 = function(label, name) {
    return {
      name: name,
      label: label
    }
  };
  var peg$f23 = function() {
      return text();
    };
  var peg$f24 = function(firstChar, otherChars) {
    return text();
  };
  var peg$f25 = function(md, ds) { return md.concat(ds); };
  var peg$f26 = function(d, d2) { return d2 };
  var peg$f27 = function(d, ds) {
    return [d].concat(ds);
  };
  var peg$f28 = function(d, c) {
    d.condition = c;
    return d;
  };
  var peg$f29 = function(md, ds) { return md.concat(ds); };
  var peg$f30 = function(d, d2) { return d2 };
  var peg$f31 = function(d, ds) { return [d].concat(ds); };
  var peg$f32 = function(d, c) {
    d.condition = c;
    return d;
  };
  var peg$f33 = function() { return text() };
  var peg$f34 = function(c) {
  return c.trim();
};
  var peg$f35 = function() {
   return {
     type: "STYLE",
     params: [{name:"width", value:text()}]
   }
 };
  var peg$f36 = function(p1, p) { return p};
  var peg$f37 = function(p1, ps) {
   return {
     type: "STYLE",
     params: [p1].concat(ps)
   }
 };
  var peg$f38 = function(styling) { return styling };
  var peg$f39 = function(namespace, control, ps) { return ps};
  var peg$f40 = function(namespace, control, params) {
   return {
     type: "CONTROL",
     controlNamespace: namespace || "",
     controlName: control,
     controlFullName: namespace? namespace+":"+control : control,
     params: params
   }
 };
  var peg$f41 = function(firstTag, tags) { return tags };
  var peg$f42 = function(firstTag, additionalTags) {
    const tags = {}
    
    for (const tag of [firstTag].concat(additionalTags)) {
      tags[tag.name] = tag.value ?? true
    }

    return {
      type: "TAGS",
      tags
    }
  };
  var peg$f43 = function(namespace) { return namespace };
  var peg$f44 = function(characters) {
    return characters.join('');
  };
  var peg$f45 = function(i, fr) { return { name:i, value:fr.value, field:fr.field};  };
  var peg$f46 = function(i, v) { return { name:i, value:v };  };
  var peg$f47 = function(i) { return {name:i, value:true}; };
  var peg$f48 = function(i) { return i };
  var peg$f49 = function(initialCharacter, otherCharacters) { return initialCharacter+otherCharacters.join(''); };
  var peg$f50 = function(firstTag, f) { return f};
  var peg$f51 = function(firstTag, additionalTags) { 
    var tags = [firstTag].concat(additionalTags)
    var result = {}

    for (var tag of tags) {
      result[tag.name] = tag.value == null ? true : tag.value
    }

    return result
  };
  var peg$f52 = function(t) {
    return {
    	name: t.name,
      value: t.value
    }
  };
  var peg$f53 = function(i, v) { return { name:i, value:v };  };
  var peg$f54 = function(i) { return {name:i, value:true}; };
  var peg$f55 = function(i) { return i };
  var peg$f56 = function(initialCharacter, otherCharacters) { return initialCharacter+otherCharacters.join(''); };
  var peg$f57 = function(chars) { return chars.join(''); };
  var peg$f58 = function(chars) {
    return chars.join('').replace(/\\"/g, '"');
  };
  var peg$f59 = function(chars) {
    return chars.join('').replace(/\\'/g, "'");
  };
  var peg$f60 = function(f) { return {value: text(), field: f} };
  var peg$f61 = function(directives, typeDef) {
      typeDef.spec.defaultDirectives = {};
      applyDirectives(typeDef.spec.defaultDirectives, directives);
      return typeDef;
    };
  var peg$f62 = function(directives, typeDef) {
      typeDef.spec.defaultDirectives = {};
      applyDirectives(typeDef.spec.defaultDirectives, directives);
      return typeDef;
    };
  var peg$f63 = function(fieldName, spec) {
      return {
        offset: location().start.offset,
        length: text().length,
        fieldName: fieldName,
        spec: spec
      };
    };
  var peg$f64 = function(typeref, cardinality, tags, formula, help, settings) {
      var result = {
        required: tags && tags.required === true,
        cardinality: cardinality,
        tags: tags || {},
        settingsText: settings || {}
      }
      
      if (formula) {    
        result.settingsText.formula = formula;
      }
      
      if (help && !result.help) {
        result.help = help;
      }
      
      if (!typeref) {
        //result.typeReference = "string";
      }
      else if(typeref.quickSyntax) {
        result.typeReference = 'QUICKSYNTAXTYPE';
        result.quickSyntaxText = typeref.quickSyntax;
      }
      else {
        result.typeReference = typeref.toLowerCase()
      }
      
      return result;
    };
  var peg$f65 = function(settings) {
    var result = {
      settingsText: settings
    }
    
    return result;
  };
  var peg$f66 = function() { return text() };
  var peg$f67 = function() { return text() };
  var peg$f68 = function() { return text() };
  var peg$f69 = function() { return text() };
  var peg$f70 = function() { return text() };
  var peg$f71 = function() { return text() };
  var peg$f72 = function() { return text() };
  var peg$f73 = function() { return text() };
  var peg$f74 = function() { return text() };
  var peg$f75 = function() { return text() };
  var peg$f76 = function() { return text() };
  var peg$f77 = function() { return text() };
  var peg$f78 = function() { return text() };
  var peg$f79 = function() { return text() };
  var peg$f80 = function() { return text() };
  var peg$f81 = function() { return text() };
  var peg$f82 = function() { return text() };
  var peg$f83 = function() { 
   return {
     quickSyntax: text().trim()
   }
  };
  var peg$f84 = function(min, max) {
      var result = {
        max: max.toLowerCase()
      }
      
      if(min)
        result.min = min.toLowerCase();
        
      return result;
    };
  var peg$f85 = function(min, max) {
      var result = {
        min: min.toLowerCase()
      }
      
      if(max)
        result.max = max.toLowerCase();
        
      return result;
    };
  var peg$f86 = function(minmax) {
      return {
        min: minmax.toLowerCase(),
        max: minmax.toLowerCase()
      }
    };
  var peg$f87 = function(chars) { return chars.join('').trim() };
  var peg$f88 = function(chars) { return chars.join('').trim(); };
  var peg$f89 = function(allSettings) {
    var result = {};

    allSettings.forEach(function(setting){
      result[setting.label] = setting.text;
    });
    
    return result;
  };
  var peg$f90 = function(first, settings) { return settings};
  var peg$f91 = function(first, others) {
    return [first].concat(others)
  };
  var peg$f92 = function(first, settings) { return settings};
  var peg$f93 = function(first, others) {
    return [first].concat(others)
  };
  var peg$f94 = function(lines) {
    return {
      label: '$default',
      text: lines.join('\n')
    }
  };
  var peg$f95 = function(label, lines) {
    return {
      label: label,
      text: lines.join('\n').replace(/\s*$/, '')
    }
  };
  var peg$f96 = function(line) { return line ; };
  var peg$f97 = function(chars) {
      var line = chars.join('').trim();
      return line;
    };
  var peg$f98 = function(label, param) {
      if (param) {
        return label + "(" + param + ")";
      } else {
        return label;
      }
    };
  var peg$f99 = function(label) {
      return label;
    };
  var peg$f100 = function(sign, digits) { return text() };
  var peg$f101 = function(digits) { return text() };
  var peg$f102 = function(chars) { return (chars.join('').trim().length == 0) };
  var peg$f103 = function(chars) {
      var line = chars.join('');
      return line;
    };
  var peg$f104 = function(char) {
      return char
    };
  var peg$currPos = options.peg$currPos | 0;
  var peg$savedPos = peg$currPos;
  var peg$posDetailsCache = [{ line: 1, column: 1 }];
  var peg$maxFailPos = peg$currPos;
  var peg$maxFailExpected = options.peg$maxFailExpected || [];
  var peg$silentFails = options.peg$silentFails | 0;

  var peg$result;

  if (options.startRule) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function offset() {
    return peg$savedPos;
  }

  function range() {
    return {
      source: peg$source,
      start: peg$savedPos,
      end: peg$currPos
    };
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== undefined
      ? location
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== undefined
      ? location
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos];
    var p;

    if (details) {
      return details;
    } else {
      if (pos >= peg$posDetailsCache.length) {
        p = peg$posDetailsCache.length - 1;
      } else {
        p = pos;
        while (!peg$posDetailsCache[--p]) {}
      }

      details = peg$posDetailsCache[p];
      details = {
        line: details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;

      return details;
    }
  }

  function peg$computeLocation(startPos, endPos, offset) {
    var startPosDetails = peg$computePosDetails(startPos);
    var endPosDetails = peg$computePosDetails(endPos);

    var res = {
      source: peg$source,
      start: {
        offset: startPos,
        line: startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line: endPosDetails.line,
        column: endPosDetails.column
      }
    };
    if (offset && peg$source && (typeof peg$source.offset === "function")) {
      res.start = peg$source.offset(res.start);
      res.end = peg$source.offset(res.end);
    }
    return res;
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$parseStart() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parse_FormDefinition();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f0(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parse_FormDefinition() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parse_RootSectionDirectives();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parse_DefinitionItem();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parse_DefinitionItem();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f1(s1);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = [];
      s1 = peg$parse_DefinitionItem();
      if (s1 !== peg$FAILED) {
        while (s1 !== peg$FAILED) {
          s0.push(s1);
          s1 = peg$parse_DefinitionItem();
        }
      } else {
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parse_RootSectionDirectives() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseSectionDirectives();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseEOL();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseEmptyLine();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f2(s1);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parse_DefinitionItem() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parse_StyledSection();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f3(s1);
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parse_StyledTypeDefinition();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$f4(s1);
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$parse_StyledMarkupBlock();
        if (s0 === peg$FAILED) {
          s0 = peg$parseEmptyLine();
        }
      }
    }

    return s0;
  }

  function peg$parse_StyledSection() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseSectionDirectives();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseLineTerminatorSequence();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseSection();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f5(s1, s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseSection();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$f6(s1);
      }
      s0 = s1;
    }

    return s0;
  }

  function peg$parseSection() {
    var s0;

    s0 = peg$parseSection1();
    if (s0 === peg$FAILED) {
      s0 = peg$parseSection2();
      if (s0 === peg$FAILED) {
        s0 = peg$parseSectionX();
      }
    }

    return s0;
  }

  function peg$parseSection1() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseLineCharacter();
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseLineCharacter();
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseLineTerminator();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c0) {
          s3 = peg$c0;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e0); }
        }
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$parseLineCharacter();
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$parseLineCharacter();
          }
          s5 = peg$parseLineTerminator();
          if (s5 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f7(s1);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSection2() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseLineCharacter();
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseLineCharacter();
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseLineTerminator();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c1) {
          s3 = peg$c1;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e1); }
        }
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$parseLineCharacter();
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$parseLineCharacter();
          }
          s5 = peg$parseLineTerminator();
          if (s5 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f8(s1);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSectionX() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = [];
    if (input.charCodeAt(peg$currPos) === 35) {
      s2 = peg$c2;
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e2); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (input.charCodeAt(peg$currPos) === 35) {
          s2 = peg$c2;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e2); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseSectionXTitle();
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      s3 = peg$parseEOL();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f9(s1, s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSectionXTitle() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseWhiteSpace();
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseWhiteSpace();
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseLineCharacter();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseLineCharacter();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f10(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parse_StyledMarkupBlock() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseFieldPosition();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseEOL();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f11(s1);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseSectionDirectives();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseEOL();
        if (s2 !== peg$FAILED) {
          s3 = peg$parse_MarkupBlock();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f12(s1, s3);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parse_MarkupBlock();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$f13(s1);
        }
        s0 = s1;
      }
    }

    return s0;
  }

  function peg$parse_MarkupBlock() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$currPos;
    peg$silentFails++;
    s4 = peg$parseTypeDefinition();
    peg$silentFails--;
    if (s4 === peg$FAILED) {
      s3 = undefined;
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    if (s3 !== peg$FAILED) {
      s4 = peg$parse_MarkupSpan();
      if (s4 !== peg$FAILED) {
        s5 = peg$currPos;
        s6 = peg$currPos;
        peg$silentFails++;
        s7 = peg$parseEOB();
        peg$silentFails--;
        if (s7 === peg$FAILED) {
          s6 = undefined;
        } else {
          peg$currPos = s6;
          s6 = peg$FAILED;
        }
        if (s6 !== peg$FAILED) {
          s7 = peg$parseEOL();
          if (s7 !== peg$FAILED) {
            s6 = [s6, s7];
            s5 = s6;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        if (s5 === peg$FAILED) {
          s5 = null;
        }
        s3 = [s3, s4, s5];
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$parseTypeDefinition();
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = undefined;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_MarkupSpan();
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$parseEOB();
            peg$silentFails--;
            if (s7 === peg$FAILED) {
              s6 = undefined;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parseEOL();
              if (s7 !== peg$FAILED) {
                s6 = [s6, s7];
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            s3 = [s3, s4, s5];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f14();
    }
    s0 = s1;

    return s0;
  }

  function peg$parse_MarkupSpan() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseFieldPosition();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f15(s1);
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseStandaloneControl();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$f16(s1);
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseTextSpan();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$f17();
        }
        s0 = s1;
      }
    }

    return s0;
  }

  function peg$parseTextSpan() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$currPos;
    peg$silentFails++;
    s4 = peg$parseFieldPosition();
    peg$silentFails--;
    if (s4 === peg$FAILED) {
      s3 = undefined;
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    if (s3 !== peg$FAILED) {
      s4 = peg$currPos;
      peg$silentFails++;
      s5 = peg$parseStandaloneControl();
      peg$silentFails--;
      if (s5 === peg$FAILED) {
        s4 = undefined;
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseLineCharacter();
        if (s5 !== peg$FAILED) {
          s3 = [s3, s4, s5];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$parseFieldPosition();
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = undefined;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          peg$silentFails++;
          s5 = peg$parseStandaloneControl();
          peg$silentFails--;
          if (s5 === peg$FAILED) {
            s4 = undefined;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseLineCharacter();
            if (s5 !== peg$FAILED) {
              s3 = [s3, s4, s5];
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f18();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseStandaloneControl() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 123) {
      s1 = peg$c3;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e3); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse__();
      s3 = peg$parseControlDirective();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse__();
        if (input.charCodeAt(peg$currPos) === 125) {
          s5 = peg$c4;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e4); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$currPos;
          peg$silentFails++;
          s7 = peg$parseEOL();
          peg$silentFails--;
          if (s7 !== peg$FAILED) {
            peg$currPos = s6;
            s6 = undefined;
          } else {
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$currPos;
            peg$silentFails++;
            s8 = peg$parseEmptyLine();
            peg$silentFails--;
            if (s8 !== peg$FAILED) {
              peg$currPos = s7;
              s7 = undefined;
            } else {
              s7 = peg$FAILED;
            }
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f19(s3);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFieldPosition() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseFieldDirectives();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseEOL();
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      s3 = peg$parseField();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f20(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseField();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$f21(s1);
      }
      s0 = s1;
    }

    return s0;
  }

  function peg$parseField() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 91) {
      s1 = peg$c5;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e5); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseFieldLabel();
      if (input.substr(peg$currPos, 2) === peg$c6) {
        s3 = peg$c6;
        peg$currPos += 2;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e6); }
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parseFieldName();
        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 93) {
            s5 = peg$c7;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e7); }
          }
          if (s5 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f22(s2, s4);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFieldLabel() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$currPos;
    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 91) {
      s4 = peg$c5;
      peg$currPos++;
    } else {
      s4 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e5); }
    }
    peg$silentFails--;
    if (s4 === peg$FAILED) {
      s3 = undefined;
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    if (s3 !== peg$FAILED) {
      s4 = peg$currPos;
      peg$silentFails++;
      if (input.charCodeAt(peg$currPos) === 93) {
        s5 = peg$c7;
        peg$currPos++;
      } else {
        s5 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e7); }
      }
      peg$silentFails--;
      if (s5 === peg$FAILED) {
        s4 = undefined;
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseLineCharacter();
        if (s5 !== peg$FAILED) {
          s3 = [s3, s4, s5];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$currPos;
      s3 = peg$currPos;
      peg$silentFails++;
      if (input.charCodeAt(peg$currPos) === 91) {
        s4 = peg$c5;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e5); }
      }
      peg$silentFails--;
      if (s4 === peg$FAILED) {
        s3 = undefined;
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$currPos;
        peg$silentFails++;
        if (input.charCodeAt(peg$currPos) === 93) {
          s5 = peg$c7;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e7); }
        }
        peg$silentFails--;
        if (s5 === peg$FAILED) {
          s4 = undefined;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseLineCharacter();
          if (s5 !== peg$FAILED) {
            s3 = [s3, s4, s5];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    }
    peg$savedPos = s0;
    s1 = peg$f23();
    s0 = s1;

    return s0;
  }

  function peg$parseFieldName() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = input.charAt(peg$currPos);
    if (peg$r0.test(s1)) {
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e8); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = input.charAt(peg$currPos);
      if (peg$r1.test(s3)) {
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e9); }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = input.charAt(peg$currPos);
        if (peg$r1.test(s3)) {
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e9); }
        }
      }
      peg$savedPos = s0;
      s0 = peg$f24(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFieldDirectives() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseFieldMultiDirective();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseEOL();
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      s3 = peg$parseFieldDirectives();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f25(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parseFieldMultiDirective();
    }

    return s0;
  }

  function peg$parseFieldMultiDirective() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 123) {
      s1 = peg$c3;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e3); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse__();
      s3 = peg$parseConditionalFieldDirective();
      if (s3 !== peg$FAILED) {
        s4 = [];
        s5 = peg$currPos;
        s6 = peg$parse__();
        if (input.charCodeAt(peg$currPos) === 124) {
          s7 = peg$c8;
          peg$currPos++;
        } else {
          s7 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e10); }
        }
        if (s7 !== peg$FAILED) {
          s8 = peg$parse__();
          s9 = peg$parseConditionalFieldDirective();
          if (s9 !== peg$FAILED) {
            peg$savedPos = s5;
            s5 = peg$f26(s3, s9);
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        while (s5 !== peg$FAILED) {
          s4.push(s5);
          s5 = peg$currPos;
          s6 = peg$parse__();
          if (input.charCodeAt(peg$currPos) === 124) {
            s7 = peg$c8;
            peg$currPos++;
          } else {
            s7 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e10); }
          }
          if (s7 !== peg$FAILED) {
            s8 = peg$parse__();
            s9 = peg$parseConditionalFieldDirective();
            if (s9 !== peg$FAILED) {
              peg$savedPos = s5;
              s5 = peg$f26(s3, s9);
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        }
        s5 = peg$parse__();
        if (input.charCodeAt(peg$currPos) === 125) {
          s6 = peg$c4;
          peg$currPos++;
        } else {
          s6 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e4); }
        }
        if (s6 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f27(s3, s4);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseConditionalFieldDirective() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseFieldDirective();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseWhiteSpace();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseWhiteSpace();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseCondition();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f28(s1, s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parseFieldDirective();
    }

    return s0;
  }

  function peg$parseSectionDirectives() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseSectionMultiDirective();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseEOL();
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      s3 = peg$parseSectionDirectives();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f29(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parseSectionMultiDirective();
    }

    return s0;
  }

  function peg$parseSectionMultiDirective() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 123) {
      s1 = peg$c3;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e3); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse__();
      s3 = peg$parseConditionalSectionDirective();
      if (s3 !== peg$FAILED) {
        s4 = [];
        s5 = peg$currPos;
        s6 = peg$parse__();
        if (input.charCodeAt(peg$currPos) === 124) {
          s7 = peg$c8;
          peg$currPos++;
        } else {
          s7 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e10); }
        }
        if (s7 !== peg$FAILED) {
          s8 = peg$parse__();
          s9 = peg$parseConditionalSectionDirective();
          if (s9 !== peg$FAILED) {
            peg$savedPos = s5;
            s5 = peg$f30(s3, s9);
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        while (s5 !== peg$FAILED) {
          s4.push(s5);
          s5 = peg$currPos;
          s6 = peg$parse__();
          if (input.charCodeAt(peg$currPos) === 124) {
            s7 = peg$c8;
            peg$currPos++;
          } else {
            s7 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e10); }
          }
          if (s7 !== peg$FAILED) {
            s8 = peg$parse__();
            s9 = peg$parseConditionalSectionDirective();
            if (s9 !== peg$FAILED) {
              peg$savedPos = s5;
              s5 = peg$f30(s3, s9);
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        }
        s5 = peg$parse__();
        if (input.charCodeAt(peg$currPos) === 125) {
          s6 = peg$c4;
          peg$currPos++;
        } else {
          s6 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e4); }
        }
        if (s6 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f31(s3, s4);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseConditionalSectionDirective() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseSectionDirective();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseWhiteSpace();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseWhiteSpace();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseCondition();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f32(s1, s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parseSectionDirective();
    }

    return s0;
  }

  function peg$parseCondition() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c9) {
      s1 = peg$c9;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e11); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseWhiteSpace();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseWhiteSpace();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = [];
        if (input.substr(peg$currPos, 2) === peg$c10) {
          s5 = peg$c10;
          peg$currPos += 2;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e12); }
        }
        if (s5 === peg$FAILED) {
          s5 = peg$currPos;
          s6 = peg$currPos;
          peg$silentFails++;
          if (input.charCodeAt(peg$currPos) === 125) {
            s7 = peg$c4;
            peg$currPos++;
          } else {
            s7 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e4); }
          }
          peg$silentFails--;
          if (s7 === peg$FAILED) {
            s6 = undefined;
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$currPos;
            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 124) {
              s8 = peg$c8;
              peg$currPos++;
            } else {
              s8 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e10); }
            }
            peg$silentFails--;
            if (s8 === peg$FAILED) {
              s7 = undefined;
            } else {
              peg$currPos = s7;
              s7 = peg$FAILED;
            }
            if (s7 !== peg$FAILED) {
              s8 = peg$parseLineCharacter();
              if (s8 !== peg$FAILED) {
                s6 = [s6, s7, s8];
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        }
        if (s5 !== peg$FAILED) {
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            if (input.substr(peg$currPos, 2) === peg$c10) {
              s5 = peg$c10;
              peg$currPos += 2;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e12); }
            }
            if (s5 === peg$FAILED) {
              s5 = peg$currPos;
              s6 = peg$currPos;
              peg$silentFails++;
              if (input.charCodeAt(peg$currPos) === 125) {
                s7 = peg$c4;
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$e4); }
              }
              peg$silentFails--;
              if (s7 === peg$FAILED) {
                s6 = undefined;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$currPos;
                peg$silentFails++;
                if (input.charCodeAt(peg$currPos) === 124) {
                  s8 = peg$c8;
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$e10); }
                }
                peg$silentFails--;
                if (s8 === peg$FAILED) {
                  s7 = undefined;
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseLineCharacter();
                  if (s8 !== peg$FAILED) {
                    s6 = [s6, s7, s8];
                    s5 = s6;
                  } else {
                    peg$currPos = s5;
                    s5 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            }
          }
        } else {
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          peg$savedPos = s3;
          s4 = peg$f33();
        }
        s3 = s4;
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f34(s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSectionDirective() {
    var s0;

    s0 = peg$parseStylingDirective();
    if (s0 === peg$FAILED) {
      s0 = peg$parseWidthDirective();
      if (s0 === peg$FAILED) {
        s0 = peg$parseTagsDirective();
      }
    }

    return s0;
  }

  function peg$parseFieldDirective() {
    var s0;

    s0 = peg$parseStylingDirective();
    if (s0 === peg$FAILED) {
      s0 = peg$parseWidthDirective();
      if (s0 === peg$FAILED) {
        s0 = peg$parseControlDirective();
      }
    }

    return s0;
  }

  function peg$parseWidthDirective() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseUnsignedNumber();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse__();
      s3 = peg$parseUnitOfWidth();
      if (s3 === peg$FAILED) {
        s3 = null;
      }
      peg$savedPos = s0;
      s0 = peg$f35();
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseStylingDirective() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parseCondition();
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseStylingParameter();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = [];
        s6 = peg$parseWhiteSpace();
        if (s6 !== peg$FAILED) {
          while (s6 !== peg$FAILED) {
            s5.push(s6);
            s6 = peg$parseWhiteSpace();
          }
        } else {
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parseStylingParameter();
          if (s6 !== peg$FAILED) {
            peg$savedPos = s4;
            s4 = peg$f36(s2, s6);
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$currPos;
          s5 = [];
          s6 = peg$parseWhiteSpace();
          if (s6 !== peg$FAILED) {
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$parseWhiteSpace();
            }
          } else {
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parseStylingParameter();
            if (s6 !== peg$FAILED) {
              peg$savedPos = s4;
              s4 = peg$f36(s2, s6);
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        peg$savedPos = s0;
        s0 = peg$f37(s2, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseStylingParameter() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8) === peg$c11) {
      s1 = peg$c11;
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e13); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 8) === peg$c12) {
        s1 = peg$c12;
        peg$currPos += 8;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e14); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c13) {
          s1 = peg$c13;
          peg$currPos += 6;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e15); }
        }
        if (s1 === peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c14) {
            s1 = peg$c14;
            peg$currPos += 4;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e16); }
          }
          if (s1 === peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c15) {
              s1 = peg$c15;
              peg$currPos += 4;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e17); }
            }
          }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$silentFails++;
      s3 = peg$parseWhiteSpace();
      if (s3 === peg$FAILED) {
        s3 = input.charAt(peg$currPos);
        if (peg$r2.test(s3)) {
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e18); }
        }
      }
      peg$silentFails--;
      if (s3 !== peg$FAILED) {
        peg$currPos = s2;
        s2 = undefined;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f38(s1);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseControlDirective() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parseCondition();
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseControlNamespace();
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      s3 = peg$parseIdentifierAllowingDashesAndHyphens();
      if (s3 !== peg$FAILED) {
        s4 = [];
        s5 = peg$currPos;
        s6 = [];
        s7 = peg$parseWhiteSpace();
        if (s7 !== peg$FAILED) {
          while (s7 !== peg$FAILED) {
            s6.push(s7);
            s7 = peg$parseWhiteSpace();
          }
        } else {
          s6 = peg$FAILED;
        }
        if (s6 !== peg$FAILED) {
          s7 = peg$parseParameter();
          if (s7 === peg$FAILED) {
            s7 = peg$parseFlagParameter();
          }
          if (s7 !== peg$FAILED) {
            peg$savedPos = s5;
            s5 = peg$f39(s2, s3, s7);
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        while (s5 !== peg$FAILED) {
          s4.push(s5);
          s5 = peg$currPos;
          s6 = [];
          s7 = peg$parseWhiteSpace();
          if (s7 !== peg$FAILED) {
            while (s7 !== peg$FAILED) {
              s6.push(s7);
              s7 = peg$parseWhiteSpace();
            }
          } else {
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseParameter();
            if (s7 === peg$FAILED) {
              s7 = peg$parseFlagParameter();
            }
            if (s7 !== peg$FAILED) {
              peg$savedPos = s5;
              s5 = peg$f39(s2, s3, s7);
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        }
        peg$savedPos = s0;
        s0 = peg$f40(s2, s3, s4);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTagsDirective() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseTag();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = [];
      s5 = peg$parseWhiteSpace();
      if (s5 !== peg$FAILED) {
        while (s5 !== peg$FAILED) {
          s4.push(s5);
          s5 = peg$parseWhiteSpace();
        }
      } else {
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseTag();
        if (s5 !== peg$FAILED) {
          peg$savedPos = s3;
          s3 = peg$f41(s1, s5);
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = [];
        s5 = peg$parseWhiteSpace();
        if (s5 !== peg$FAILED) {
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$parseWhiteSpace();
          }
        } else {
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseTag();
          if (s5 !== peg$FAILED) {
            peg$savedPos = s3;
            s3 = peg$f41(s1, s5);
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f42(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseControlNamespace() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseIdentifierAllowingDashesAndHyphens();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 58) {
        s2 = peg$c16;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e19); }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f43(s1);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTypeSettingsSectionParam() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = input.charAt(peg$currPos);
    if (peg$r3.test(s2)) {
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e20); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = input.charAt(peg$currPos);
        if (peg$r3.test(s2)) {
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e20); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f44(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseParameter() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseParamName();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse__();
      if (input.charCodeAt(peg$currPos) === 61) {
        s3 = peg$c17;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e21); }
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parse__();
        s5 = peg$parseFieldReferencingParameterValue();
        if (s5 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f45(s1, s5);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseParamName();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse__();
        if (input.charCodeAt(peg$currPos) === 61) {
          s3 = peg$c17;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e21); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse__();
          s5 = peg$parseDoubleQuotedParamValue();
          if (s5 === peg$FAILED) {
            s5 = peg$parseSingleQuotedParamValue();
            if (s5 === peg$FAILED) {
              s5 = peg$parseParamValue();
            }
          }
          if (s5 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f46(s1, s5);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseFlagParameter() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseParamName();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f47(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseParamName() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c9) {
      s3 = peg$c9;
      peg$currPos += 2;
    } else {
      s3 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e11); }
    }
    if (s3 !== peg$FAILED) {
      s4 = peg$parseWhiteSpace();
      if (s4 !== peg$FAILED) {
        s3 = [s3, s4];
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseIdentifierAllowingDashesAndHyphens();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f48(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIdentifierAllowingDashes() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = input.charAt(peg$currPos);
    if (peg$r4.test(s1)) {
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e22); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = input.charAt(peg$currPos);
      if (peg$r3.test(s3)) {
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e20); }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = input.charAt(peg$currPos);
        if (peg$r3.test(s3)) {
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e20); }
        }
      }
      peg$savedPos = s0;
      s0 = peg$f49(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIdentifierAllowingDashesAndHyphens() {
    var s0;

    s0 = peg$parseIdentifierAllowingDashes();
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 45) {
        s0 = peg$c18;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e23); }
      }
    }

    return s0;
  }

  function peg$parseQuickTags() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 60) {
      s1 = peg$c19;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e24); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse__();
      s3 = peg$parseTag();
      if (s3 !== peg$FAILED) {
        s4 = [];
        s5 = peg$currPos;
        s6 = [];
        s7 = peg$parseWhiteSpace();
        if (s7 !== peg$FAILED) {
          while (s7 !== peg$FAILED) {
            s6.push(s7);
            s7 = peg$parseWhiteSpace();
          }
        } else {
          s6 = peg$FAILED;
        }
        if (s6 !== peg$FAILED) {
          s7 = peg$parseTag();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s5;
            s5 = peg$f50(s3, s7);
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        while (s5 !== peg$FAILED) {
          s4.push(s5);
          s5 = peg$currPos;
          s6 = [];
          s7 = peg$parseWhiteSpace();
          if (s7 !== peg$FAILED) {
            while (s7 !== peg$FAILED) {
              s6.push(s7);
              s7 = peg$parseWhiteSpace();
            }
          } else {
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseTag();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s5;
              s5 = peg$f50(s3, s7);
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        }
        s5 = peg$parse__();
        if (input.charCodeAt(peg$currPos) === 62) {
          s6 = peg$c20;
          peg$currPos++;
        } else {
          s6 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e25); }
        }
        if (s6 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f51(s3, s4);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTag() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseTagWithValue();
    if (s1 === peg$FAILED) {
      s1 = peg$parseTagFlag();
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f52(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseTagWithValue() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseTagName();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 61) {
        s2 = peg$c17;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e21); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseDoubleQuotedParamValue();
        if (s3 === peg$FAILED) {
          s3 = peg$parseSingleQuotedParamValue();
          if (s3 === peg$FAILED) {
            s3 = peg$parseParamValue();
          }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f53(s1, s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTagFlag() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseTagName();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f54(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseTagName() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c9) {
      s3 = peg$c9;
      peg$currPos += 2;
    } else {
      s3 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e11); }
    }
    if (s3 !== peg$FAILED) {
      s4 = peg$parseWhiteSpace();
      if (s4 !== peg$FAILED) {
        s3 = [s3, s4];
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseIdentifierAllowingDashesAndColons();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f55(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIdentifierAllowingDashesAndColons() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = input.charAt(peg$currPos);
    if (peg$r4.test(s1)) {
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e22); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = input.charAt(peg$currPos);
      if (peg$r5.test(s3)) {
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e26); }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = input.charAt(peg$currPos);
        if (peg$r5.test(s3)) {
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e26); }
        }
      }
      peg$savedPos = s0;
      s0 = peg$f56(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseUnitOfWidth() {
    var s0;

    if (input.substr(peg$currPos, 2) === peg$c21) {
      s0 = peg$c21;
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e27); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c22) {
        s0 = peg$c22;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e28); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c23) {
          s0 = peg$c23;
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e29); }
        }
        if (s0 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 42) {
            s0 = peg$c24;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e30); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c25) {
              s0 = peg$c25;
              peg$currPos += 3;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e31); }
            }
            if (s0 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 37) {
                s0 = peg$c26;
                peg$currPos++;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$e32); }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseParamValue() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = input.charAt(peg$currPos);
    if (peg$r6.test(s2)) {
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e33); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = input.charAt(peg$currPos);
        if (peg$r6.test(s2)) {
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e33); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f57(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseDoubleQuotedParamValue() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 34) {
      s1 = peg$c27;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e34); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (input.substr(peg$currPos, 2) === peg$c28) {
        s3 = peg$c28;
        peg$currPos += 2;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e35); }
      }
      if (s3 === peg$FAILED) {
        s3 = input.charAt(peg$currPos);
        if (peg$r7.test(s3)) {
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e36); }
        }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        if (input.substr(peg$currPos, 2) === peg$c28) {
          s3 = peg$c28;
          peg$currPos += 2;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e35); }
        }
        if (s3 === peg$FAILED) {
          s3 = input.charAt(peg$currPos);
          if (peg$r7.test(s3)) {
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e36); }
          }
        }
      }
      if (input.charCodeAt(peg$currPos) === 34) {
        s3 = peg$c27;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e34); }
      }
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f58(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSingleQuotedParamValue() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 39) {
      s1 = peg$c29;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e37); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (input.substr(peg$currPos, 2) === peg$c30) {
        s3 = peg$c30;
        peg$currPos += 2;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e38); }
      }
      if (s3 === peg$FAILED) {
        s3 = input.charAt(peg$currPos);
        if (peg$r8.test(s3)) {
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e39); }
        }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        if (input.substr(peg$currPos, 2) === peg$c30) {
          s3 = peg$c30;
          peg$currPos += 2;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e38); }
        }
        if (s3 === peg$FAILED) {
          s3 = input.charAt(peg$currPos);
          if (peg$r8.test(s3)) {
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e39); }
          }
        }
      }
      if (input.charCodeAt(peg$currPos) === 39) {
        s3 = peg$c29;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e37); }
      }
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f59(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFieldReferencingParameterValue() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 91) {
      s1 = peg$c5;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e5); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseFieldName();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 93) {
          s3 = peg$c7;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e7); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f60(s2);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parse_StyledTypeDefinition() {
    var s0, s1, s2, s3;

    s0 = peg$parseTypeDefinition();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseFieldDirectives();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseTypeDefinition();
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f61(s1, s2);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseFieldDirectives();
        if (s1 !== peg$FAILED) {
          s2 = peg$parseEOL();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseTypeDefinition();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f62(s1, s3);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    return s0;
  }

  function peg$parseTypeDefinition() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 91) {
      s1 = peg$c5;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e5); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseFieldName();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 93) {
          s3 = peg$c7;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e7); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse__();
          if (input.charCodeAt(peg$currPos) === 58) {
            s5 = peg$c16;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e19); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse__();
            s7 = peg$parseTypeSpec();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f63(s2, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTypeSpec() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    s0 = peg$currPos;
    s1 = peg$parseTypeReference();
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    s2 = peg$parse__();
    s3 = peg$parseCardinality();
    if (s3 === peg$FAILED) {
      s3 = null;
    }
    s4 = peg$parse__();
    s5 = peg$parseQuickTags();
    if (s5 === peg$FAILED) {
      s5 = null;
    }
    s6 = peg$parse__();
    s7 = peg$parseFormula();
    if (s7 === peg$FAILED) {
      s7 = null;
    }
    s8 = peg$parse__();
    s9 = peg$parseQuickHelp();
    if (s9 === peg$FAILED) {
      s9 = null;
    }
    s10 = peg$parseEOL();
    if (s10 !== peg$FAILED) {
      s11 = peg$parseTypeSettings();
      if (s11 === peg$FAILED) {
        s11 = null;
      }
      peg$savedPos = s0;
      s0 = peg$f64(s1, s3, s5, s7, s9, s11);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseEOL();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseTypeSettings();
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f65(s2);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseTypeReference() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = input.substr(peg$currPos, 4);
    if (s1.toLowerCase() === peg$c31) {
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e40); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$silentFails++;
      if (input.charCodeAt(peg$currPos) === 40) {
        s3 = peg$c32;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e41); }
      }
      if (s3 === peg$FAILED) {
        s3 = peg$parseWhiteSpace();
        if (s3 === peg$FAILED) {
          s3 = peg$parseEOL();
        }
      }
      peg$silentFails--;
      if (s3 !== peg$FAILED) {
        peg$currPos = s2;
        s2 = undefined;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f66();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = input.substr(peg$currPos, 8);
      if (s1.toLowerCase() === peg$c33) {
        peg$currPos += 8;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e42); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        if (input.charCodeAt(peg$currPos) === 40) {
          s3 = peg$c32;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e41); }
        }
        if (s3 === peg$FAILED) {
          s3 = peg$parseWhiteSpace();
          if (s3 === peg$FAILED) {
            s3 = peg$parseEOL();
          }
        }
        peg$silentFails--;
        if (s3 !== peg$FAILED) {
          peg$currPos = s2;
          s2 = undefined;
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f67();
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = input.substr(peg$currPos, 6);
        if (s1.toLowerCase() === peg$c34) {
          peg$currPos += 6;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e43); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$currPos;
          peg$silentFails++;
          if (input.charCodeAt(peg$currPos) === 40) {
            s3 = peg$c32;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e41); }
          }
          if (s3 === peg$FAILED) {
            s3 = peg$parseWhiteSpace();
            if (s3 === peg$FAILED) {
              s3 = peg$parseEOL();
            }
          }
          peg$silentFails--;
          if (s3 !== peg$FAILED) {
            peg$currPos = s2;
            s2 = undefined;
          } else {
            s2 = peg$FAILED;
          }
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f68();
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = input.substr(peg$currPos, 6);
          if (s1.toLowerCase() === peg$c35) {
            peg$currPos += 6;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e44); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$currPos;
            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 40) {
              s3 = peg$c32;
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e41); }
            }
            if (s3 === peg$FAILED) {
              s3 = peg$parseWhiteSpace();
              if (s3 === peg$FAILED) {
                s3 = peg$parseEOL();
              }
            }
            peg$silentFails--;
            if (s3 !== peg$FAILED) {
              peg$currPos = s2;
              s2 = undefined;
            } else {
              s2 = peg$FAILED;
            }
            if (s2 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f69();
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = input.substr(peg$currPos, 6);
            if (s1.toLowerCase() === peg$c36) {
              peg$currPos += 6;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e45); }
            }
            if (s1 !== peg$FAILED) {
              s2 = peg$currPos;
              peg$silentFails++;
              if (input.charCodeAt(peg$currPos) === 40) {
                s3 = peg$c32;
                peg$currPos++;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$e41); }
              }
              if (s3 === peg$FAILED) {
                s3 = peg$parseWhiteSpace();
                if (s3 === peg$FAILED) {
                  s3 = peg$parseEOL();
                }
              }
              peg$silentFails--;
              if (s3 !== peg$FAILED) {
                peg$currPos = s2;
                s2 = undefined;
              } else {
                s2 = peg$FAILED;
              }
              if (s2 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f70();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = input.substr(peg$currPos, 4);
              if (s1.toLowerCase() === peg$c37) {
                peg$currPos += 4;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$e46); }
              }
              if (s1 !== peg$FAILED) {
                s2 = peg$currPos;
                peg$silentFails++;
                if (input.charCodeAt(peg$currPos) === 40) {
                  s3 = peg$c32;
                  peg$currPos++;
                } else {
                  s3 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$e41); }
                }
                if (s3 === peg$FAILED) {
                  s3 = peg$parseWhiteSpace();
                  if (s3 === peg$FAILED) {
                    s3 = peg$parseEOL();
                  }
                }
                peg$silentFails--;
                if (s3 !== peg$FAILED) {
                  peg$currPos = s2;
                  s2 = undefined;
                } else {
                  s2 = peg$FAILED;
                }
                if (s2 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s0 = peg$f71();
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = input.substr(peg$currPos, 8);
                if (s1.toLowerCase() === peg$c38) {
                  peg$currPos += 8;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$e47); }
                }
                if (s1 !== peg$FAILED) {
                  s2 = peg$currPos;
                  peg$silentFails++;
                  if (input.charCodeAt(peg$currPos) === 40) {
                    s3 = peg$c32;
                    peg$currPos++;
                  } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$e41); }
                  }
                  if (s3 === peg$FAILED) {
                    s3 = peg$parseWhiteSpace();
                    if (s3 === peg$FAILED) {
                      s3 = peg$parseEOL();
                    }
                  }
                  peg$silentFails--;
                  if (s3 !== peg$FAILED) {
                    peg$currPos = s2;
                    s2 = undefined;
                  } else {
                    s2 = peg$FAILED;
                  }
                  if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f72();
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  s1 = input.substr(peg$currPos, 4);
                  if (s1.toLowerCase() === peg$c39) {
                    peg$currPos += 4;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$e48); }
                  }
                  if (s1 !== peg$FAILED) {
                    s2 = peg$currPos;
                    peg$silentFails++;
                    if (input.charCodeAt(peg$currPos) === 40) {
                      s3 = peg$c32;
                      peg$currPos++;
                    } else {
                      s3 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$e41); }
                    }
                    if (s3 === peg$FAILED) {
                      s3 = peg$parseWhiteSpace();
                      if (s3 === peg$FAILED) {
                        s3 = peg$parseEOL();
                      }
                    }
                    peg$silentFails--;
                    if (s3 !== peg$FAILED) {
                      peg$currPos = s2;
                      s2 = undefined;
                    } else {
                      s2 = peg$FAILED;
                    }
                    if (s2 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s0 = peg$f73();
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                  if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = input.substr(peg$currPos, 9);
                    if (s1.toLowerCase() === peg$c40) {
                      peg$currPos += 9;
                    } else {
                      s1 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$e49); }
                    }
                    if (s1 !== peg$FAILED) {
                      s2 = peg$currPos;
                      peg$silentFails++;
                      if (input.charCodeAt(peg$currPos) === 40) {
                        s3 = peg$c32;
                        peg$currPos++;
                      } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$e41); }
                      }
                      if (s3 === peg$FAILED) {
                        s3 = peg$parseWhiteSpace();
                        if (s3 === peg$FAILED) {
                          s3 = peg$parseEOL();
                        }
                      }
                      peg$silentFails--;
                      if (s3 !== peg$FAILED) {
                        peg$currPos = s2;
                        s2 = undefined;
                      } else {
                        s2 = peg$FAILED;
                      }
                      if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f74();
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                    if (s0 === peg$FAILED) {
                      s0 = peg$currPos;
                      s1 = input.substr(peg$currPos, 4);
                      if (s1.toLowerCase() === peg$c41) {
                        peg$currPos += 4;
                      } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$e50); }
                      }
                      if (s1 !== peg$FAILED) {
                        s2 = peg$currPos;
                        peg$silentFails++;
                        if (input.charCodeAt(peg$currPos) === 40) {
                          s3 = peg$c32;
                          peg$currPos++;
                        } else {
                          s3 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$e41); }
                        }
                        if (s3 === peg$FAILED) {
                          s3 = peg$parseWhiteSpace();
                          if (s3 === peg$FAILED) {
                            s3 = peg$parseEOL();
                          }
                        }
                        peg$silentFails--;
                        if (s3 !== peg$FAILED) {
                          peg$currPos = s2;
                          s2 = undefined;
                        } else {
                          s2 = peg$FAILED;
                        }
                        if (s2 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s0 = peg$f75();
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                      if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        s1 = input.substr(peg$currPos, 8);
                        if (s1.toLowerCase() === peg$c42) {
                          peg$currPos += 8;
                        } else {
                          s1 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$e51); }
                        }
                        if (s1 !== peg$FAILED) {
                          s2 = peg$currPos;
                          peg$silentFails++;
                          if (input.charCodeAt(peg$currPos) === 40) {
                            s3 = peg$c32;
                            peg$currPos++;
                          } else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$e41); }
                          }
                          if (s3 === peg$FAILED) {
                            s3 = peg$parseWhiteSpace();
                            if (s3 === peg$FAILED) {
                              s3 = peg$parseEOL();
                            }
                          }
                          peg$silentFails--;
                          if (s3 !== peg$FAILED) {
                            peg$currPos = s2;
                            s2 = undefined;
                          } else {
                            s2 = peg$FAILED;
                          }
                          if (s2 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s0 = peg$f76();
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                        if (s0 === peg$FAILED) {
                          s0 = peg$currPos;
                          s1 = input.substr(peg$currPos, 3);
                          if (s1.toLowerCase() === peg$c43) {
                            peg$currPos += 3;
                          } else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$e52); }
                          }
                          if (s1 !== peg$FAILED) {
                            s2 = peg$currPos;
                            peg$silentFails++;
                            if (input.charCodeAt(peg$currPos) === 40) {
                              s3 = peg$c32;
                              peg$currPos++;
                            } else {
                              s3 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$e41); }
                            }
                            if (s3 === peg$FAILED) {
                              s3 = peg$parseWhiteSpace();
                              if (s3 === peg$FAILED) {
                                s3 = peg$parseEOL();
                              }
                            }
                            peg$silentFails--;
                            if (s3 !== peg$FAILED) {
                              peg$currPos = s2;
                              s2 = undefined;
                            } else {
                              s2 = peg$FAILED;
                            }
                            if (s2 !== peg$FAILED) {
                              peg$savedPos = s0;
                              s0 = peg$f77();
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                          if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            s1 = input.substr(peg$currPos, 4);
                            if (s1.toLowerCase() === peg$c44) {
                              peg$currPos += 4;
                            } else {
                              s1 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$e53); }
                            }
                            if (s1 !== peg$FAILED) {
                              s2 = peg$currPos;
                              peg$silentFails++;
                              if (input.charCodeAt(peg$currPos) === 40) {
                                s3 = peg$c32;
                                peg$currPos++;
                              } else {
                                s3 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$e41); }
                              }
                              if (s3 === peg$FAILED) {
                                s3 = peg$parseWhiteSpace();
                                if (s3 === peg$FAILED) {
                                  s3 = peg$parseEOL();
                                }
                              }
                              peg$silentFails--;
                              if (s3 !== peg$FAILED) {
                                peg$currPos = s2;
                                s2 = undefined;
                              } else {
                                s2 = peg$FAILED;
                              }
                              if (s2 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s0 = peg$f78();
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                            if (s0 === peg$FAILED) {
                              s0 = peg$currPos;
                              s1 = input.substr(peg$currPos, 5);
                              if (s1.toLowerCase() === peg$c45) {
                                peg$currPos += 5;
                              } else {
                                s1 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$e54); }
                              }
                              if (s1 !== peg$FAILED) {
                                s2 = peg$currPos;
                                peg$silentFails++;
                                if (input.charCodeAt(peg$currPos) === 40) {
                                  s3 = peg$c32;
                                  peg$currPos++;
                                } else {
                                  s3 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$e41); }
                                }
                                if (s3 === peg$FAILED) {
                                  s3 = peg$parseWhiteSpace();
                                  if (s3 === peg$FAILED) {
                                    s3 = peg$parseEOL();
                                  }
                                }
                                peg$silentFails--;
                                if (s3 !== peg$FAILED) {
                                  peg$currPos = s2;
                                  s2 = undefined;
                                } else {
                                  s2 = peg$FAILED;
                                }
                                if (s2 !== peg$FAILED) {
                                  peg$savedPos = s0;
                                  s0 = peg$f79();
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                              if (s0 === peg$FAILED) {
                                s0 = peg$currPos;
                                s1 = input.substr(peg$currPos, 7);
                                if (s1.toLowerCase() === peg$c46) {
                                  peg$currPos += 7;
                                } else {
                                  s1 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$e55); }
                                }
                                if (s1 !== peg$FAILED) {
                                  s2 = peg$currPos;
                                  peg$silentFails++;
                                  if (input.charCodeAt(peg$currPos) === 40) {
                                    s3 = peg$c32;
                                    peg$currPos++;
                                  } else {
                                    s3 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$e41); }
                                  }
                                  if (s3 === peg$FAILED) {
                                    s3 = peg$parseWhiteSpace();
                                    if (s3 === peg$FAILED) {
                                      s3 = peg$parseEOL();
                                    }
                                  }
                                  peg$silentFails--;
                                  if (s3 !== peg$FAILED) {
                                    peg$currPos = s2;
                                    s2 = undefined;
                                  } else {
                                    s2 = peg$FAILED;
                                  }
                                  if (s2 !== peg$FAILED) {
                                    peg$savedPos = s0;
                                    s0 = peg$f80();
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                                if (s0 === peg$FAILED) {
                                  s0 = peg$currPos;
                                  s1 = input.substr(peg$currPos, 8);
                                  if (s1.toLowerCase() === peg$c47) {
                                    peg$currPos += 8;
                                  } else {
                                    s1 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$e56); }
                                  }
                                  if (s1 !== peg$FAILED) {
                                    s2 = peg$currPos;
                                    peg$silentFails++;
                                    if (input.charCodeAt(peg$currPos) === 40) {
                                      s3 = peg$c32;
                                      peg$currPos++;
                                    } else {
                                      s3 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$e41); }
                                    }
                                    if (s3 === peg$FAILED) {
                                      s3 = peg$parseWhiteSpace();
                                      if (s3 === peg$FAILED) {
                                        s3 = peg$parseEOL();
                                      }
                                    }
                                    peg$silentFails--;
                                    if (s3 !== peg$FAILED) {
                                      peg$currPos = s2;
                                      s2 = undefined;
                                    } else {
                                      s2 = peg$FAILED;
                                    }
                                    if (s2 !== peg$FAILED) {
                                      peg$savedPos = s0;
                                      s0 = peg$f81();
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                  if (s0 === peg$FAILED) {
                                    s0 = peg$currPos;
                                    s1 = input.substr(peg$currPos, 11);
                                    if (s1.toLowerCase() === peg$c48) {
                                      peg$currPos += 11;
                                    } else {
                                      s1 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$e57); }
                                    }
                                    if (s1 !== peg$FAILED) {
                                      s2 = peg$currPos;
                                      peg$silentFails++;
                                      if (input.charCodeAt(peg$currPos) === 40) {
                                        s3 = peg$c32;
                                        peg$currPos++;
                                      } else {
                                        s3 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$e41); }
                                      }
                                      if (s3 === peg$FAILED) {
                                        s3 = peg$parseWhiteSpace();
                                        if (s3 === peg$FAILED) {
                                          s3 = peg$parseEOL();
                                        }
                                      }
                                      peg$silentFails--;
                                      if (s3 !== peg$FAILED) {
                                        peg$currPos = s2;
                                        s2 = undefined;
                                      } else {
                                        s2 = peg$FAILED;
                                      }
                                      if (s2 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s0 = peg$f82();
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                    if (s0 === peg$FAILED) {
                                      s0 = peg$parseTypeReferenceQuickSyntax();
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseTypeReferenceQuickSyntax() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$currPos;
    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 40) {
      s4 = peg$c32;
      peg$currPos++;
    } else {
      s4 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e41); }
    }
    peg$silentFails--;
    if (s4 === peg$FAILED) {
      s3 = undefined;
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    if (s3 !== peg$FAILED) {
      s4 = peg$currPos;
      peg$silentFails++;
      if (input.charCodeAt(peg$currPos) === 34) {
        s5 = peg$c27;
        peg$currPos++;
      } else {
        s5 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e34); }
      }
      peg$silentFails--;
      if (s5 === peg$FAILED) {
        s4 = undefined;
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$currPos;
        peg$silentFails++;
        if (input.charCodeAt(peg$currPos) === 61) {
          s6 = peg$c17;
          peg$currPos++;
        } else {
          s6 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e21); }
        }
        peg$silentFails--;
        if (s6 === peg$FAILED) {
          s5 = undefined;
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$currPos;
          peg$silentFails++;
          if (input.charCodeAt(peg$currPos) === 60) {
            s7 = peg$c19;
            peg$currPos++;
          } else {
            s7 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e24); }
          }
          peg$silentFails--;
          if (s7 === peg$FAILED) {
            s6 = undefined;
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseLineCharacter();
            if (s7 !== peg$FAILED) {
              s3 = [s3, s4, s5, s6, s7];
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        if (input.charCodeAt(peg$currPos) === 40) {
          s4 = peg$c32;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e41); }
        }
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = undefined;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          peg$silentFails++;
          if (input.charCodeAt(peg$currPos) === 34) {
            s5 = peg$c27;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e34); }
          }
          peg$silentFails--;
          if (s5 === peg$FAILED) {
            s4 = undefined;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 61) {
              s6 = peg$c17;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e21); }
            }
            peg$silentFails--;
            if (s6 === peg$FAILED) {
              s5 = undefined;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$currPos;
              peg$silentFails++;
              if (input.charCodeAt(peg$currPos) === 60) {
                s7 = peg$c19;
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$e24); }
              }
              peg$silentFails--;
              if (s7 === peg$FAILED) {
                s6 = undefined;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseLineCharacter();
                if (s7 !== peg$FAILED) {
                  s3 = [s3, s4, s5, s6, s7];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$FAILED;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f83();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseCardinality() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 40) {
      s1 = peg$c32;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e41); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse__();
      s3 = peg$parseUnsignedNumber();
      if (s3 === peg$FAILED) {
        s3 = input.charAt(peg$currPos);
        if (s3.toLowerCase() === peg$c49) {
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e58); }
        }
      }
      if (s3 === peg$FAILED) {
        s3 = null;
      }
      s4 = peg$parse__();
      if (input.charCodeAt(peg$currPos) === 45) {
        s5 = peg$c18;
        peg$currPos++;
      } else {
        s5 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e23); }
      }
      if (s5 !== peg$FAILED) {
        s6 = peg$parse__();
        s7 = peg$parseUnsignedNumber();
        if (s7 === peg$FAILED) {
          s7 = input.charAt(peg$currPos);
          if (s7.toLowerCase() === peg$c49) {
            peg$currPos++;
          } else {
            s7 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e58); }
          }
        }
        if (s7 !== peg$FAILED) {
          s8 = peg$parse__();
          if (input.charCodeAt(peg$currPos) === 41) {
            s9 = peg$c50;
            peg$currPos++;
          } else {
            s9 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e59); }
          }
          if (s9 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f84(s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 40) {
        s1 = peg$c32;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e41); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse__();
        s3 = peg$parseUnsignedNumber();
        if (s3 === peg$FAILED) {
          s3 = input.charAt(peg$currPos);
          if (s3.toLowerCase() === peg$c49) {
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e58); }
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse__();
          if (input.charCodeAt(peg$currPos) === 45) {
            s5 = peg$c18;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e23); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse__();
            s7 = peg$parseUnsignedNumber();
            if (s7 === peg$FAILED) {
              s7 = input.charAt(peg$currPos);
              if (s7.toLowerCase() === peg$c49) {
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$e58); }
              }
            }
            if (s7 === peg$FAILED) {
              s7 = null;
            }
            s8 = peg$parse__();
            if (input.charCodeAt(peg$currPos) === 41) {
              s9 = peg$c50;
              peg$currPos++;
            } else {
              s9 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e59); }
            }
            if (s9 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f85(s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 40) {
          s1 = peg$c32;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e41); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse__();
          s3 = peg$parseUnsignedNumber();
          if (s3 === peg$FAILED) {
            s3 = input.charAt(peg$currPos);
            if (s3.toLowerCase() === peg$c49) {
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e58); }
            }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse__();
            if (input.charCodeAt(peg$currPos) === 41) {
              s5 = peg$c50;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e59); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f86(s3);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    return s0;
  }

  function peg$parseFormula() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 61) {
      s1 = peg$c17;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e21); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse__();
      s3 = [];
      s4 = input.charAt(peg$currPos);
      if (peg$r9.test(s4)) {
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e60); }
      }
      if (s4 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c28) {
          s4 = peg$c28;
          peg$currPos += 2;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e35); }
        }
      }
      while (s4 !== peg$FAILED) {
        s3.push(s4);
        s4 = input.charAt(peg$currPos);
        if (peg$r9.test(s4)) {
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e60); }
        }
        if (s4 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c28) {
            s4 = peg$c28;
            peg$currPos += 2;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e35); }
          }
        }
      }
      peg$savedPos = s0;
      s0 = peg$f87(s3);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseQuickHelp() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 34) {
      s1 = peg$c27;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e34); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = input.charAt(peg$currPos);
      if (peg$r7.test(s3)) {
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e36); }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = input.charAt(peg$currPos);
        if (peg$r7.test(s3)) {
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e36); }
        }
      }
      if (input.charCodeAt(peg$currPos) === 34) {
        s3 = peg$c27;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e34); }
      }
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f88(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTypeSettings() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseTypeSettingSets();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f89(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseTypeSettingSets() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseLabeledTypeSettingsBlock();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = [];
      s5 = peg$parseEmptyLine();
      if (s5 !== peg$FAILED) {
        while (s5 !== peg$FAILED) {
          s4.push(s5);
          s5 = peg$parseEmptyLine();
        }
      } else {
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseLabeledTypeSettingsBlock();
        if (s5 !== peg$FAILED) {
          peg$savedPos = s3;
          s3 = peg$f90(s1, s5);
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = [];
        s5 = peg$parseEmptyLine();
        if (s5 !== peg$FAILED) {
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$parseEmptyLine();
          }
        } else {
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseLabeledTypeSettingsBlock();
          if (s5 !== peg$FAILED) {
            peg$savedPos = s3;
            s3 = peg$f90(s1, s5);
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f91(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseDefaultTypeSettingsBlock();
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = [];
        s5 = peg$parseEmptyLine();
        if (s5 !== peg$FAILED) {
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$parseEmptyLine();
          }
        } else {
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseLabeledTypeSettingsBlock();
          if (s5 !== peg$FAILED) {
            peg$savedPos = s3;
            s3 = peg$f92(s1, s5);
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = [];
          s5 = peg$parseEmptyLine();
          if (s5 !== peg$FAILED) {
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$parseEmptyLine();
            }
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseLabeledTypeSettingsBlock();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s3;
              s3 = peg$f92(s1, s5);
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        peg$savedPos = s0;
        s0 = peg$f93(s1, s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseDefaultTypeSettingsBlock() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseTypeSettingsLine();
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseTypeSettingsLine();
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f94(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseLabeledTypeSettingsBlock() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseTypeSettingsLabel();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseTypeSettingsLineOrEmptyLine();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseTypeSettingsLineOrEmptyLine();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f95(s1, s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTypeSettingsLineOrEmptyLine() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parseTypeSettingsLabel();
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseEmptyLine();
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parseEmptyLine();
      }
      s3 = peg$currPos;
      peg$silentFails++;
      s4 = peg$parseTypeSettingsLabel();
      peg$silentFails--;
      if (s4 === peg$FAILED) {
        s3 = undefined;
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parseTypeSettingsLine();
        if (s4 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f96(s4);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTypeSettingsLine() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseIndent();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseLineCharacter();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseLineCharacter();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseEOL();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f97(s2);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTypeSettingsLabel() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15;

    s0 = peg$currPos;
    s1 = peg$parseIndent();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseIdentifierAllowingDashes();
      if (s2 !== peg$FAILED) {
        s3 = peg$parse__();
        if (input.charCodeAt(peg$currPos) === 40) {
          s4 = peg$c32;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e41); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parse__();
          s6 = peg$parseTypeSettingsSectionParam();
          if (s6 === peg$FAILED) {
            s6 = null;
          }
          s7 = peg$parse__();
          if (input.charCodeAt(peg$currPos) === 41) {
            s8 = peg$c50;
            peg$currPos++;
          } else {
            s8 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e59); }
          }
          if (s8 !== peg$FAILED) {
            s9 = peg$parse__();
            if (input.charCodeAt(peg$currPos) === 58) {
              s10 = peg$c16;
              peg$currPos++;
            } else {
              s10 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e19); }
            }
            if (s10 !== peg$FAILED) {
              s11 = peg$parseEOL();
              if (s11 !== peg$FAILED) {
                s12 = peg$parseIndent();
                if (s12 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c1) {
                    s13 = peg$c1;
                    peg$currPos += 3;
                  } else {
                    s13 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$e1); }
                  }
                  if (s13 !== peg$FAILED) {
                    s14 = [];
                    if (input.charCodeAt(peg$currPos) === 45) {
                      s15 = peg$c18;
                      peg$currPos++;
                    } else {
                      s15 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$e23); }
                    }
                    while (s15 !== peg$FAILED) {
                      s14.push(s15);
                      if (input.charCodeAt(peg$currPos) === 45) {
                        s15 = peg$c18;
                        peg$currPos++;
                      } else {
                        s15 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$e23); }
                      }
                    }
                    peg$savedPos = s0;
                    s0 = peg$f98(s2, s6);
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseIndent();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseIdentifierAllowingDashes();
        if (s2 !== peg$FAILED) {
          s3 = peg$parse__();
          if (input.charCodeAt(peg$currPos) === 58) {
            s4 = peg$c16;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e19); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseEOL();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseIndent();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c1) {
                  s7 = peg$c1;
                  peg$currPos += 3;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$e1); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = [];
                  if (input.charCodeAt(peg$currPos) === 45) {
                    s9 = peg$c18;
                    peg$currPos++;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$e23); }
                  }
                  while (s9 !== peg$FAILED) {
                    s8.push(s9);
                    if (input.charCodeAt(peg$currPos) === 45) {
                      s9 = peg$c18;
                      peg$currPos++;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$e23); }
                    }
                  }
                  s9 = peg$parseEOL();
                  if (s9 !== peg$FAILED) {
                    s10 = peg$currPos;
                    peg$silentFails++;
                    s11 = peg$parseIndent();
                    peg$silentFails--;
                    if (s11 !== peg$FAILED) {
                      peg$currPos = s10;
                      s10 = undefined;
                    } else {
                      s10 = peg$FAILED;
                    }
                    if (s10 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s0 = peg$f99(s2);
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseSignedNumber() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 45) {
      s1 = peg$c18;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e23); }
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    s2 = [];
    s3 = input.charAt(peg$currPos);
    if (peg$r10.test(s3)) {
      peg$currPos++;
    } else {
      s3 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e61); }
    }
    if (s3 !== peg$FAILED) {
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = input.charAt(peg$currPos);
        if (peg$r10.test(s3)) {
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e61); }
        }
      }
    } else {
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      peg$savedPos = s0;
      s0 = peg$f100(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseUnsignedNumber() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = input.charAt(peg$currPos);
    if (peg$r10.test(s2)) {
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e61); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = input.charAt(peg$currPos);
        if (peg$r10.test(s2)) {
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e61); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f101(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseEmptyLine() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseWhiteSpace();
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$parseWhiteSpace();
    }
    s2 = peg$parseLineTerminatorSequence();
    if (s2 !== peg$FAILED) {
      peg$savedPos = peg$currPos;
      s3 = peg$f102(s1);
      if (s3) {
        s3 = undefined;
      } else {
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        s1 = [s1, s2, s3];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parseLineTerminatorSequence();
    }

    return s0;
  }

  function peg$parseDoubleEmptyLine() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseEmptyLine();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseEmptyLine();
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseEndOfIndent() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseEmptyLine();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseEmptyLine();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseEmptyLine();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$parseWhiteSpace();
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = undefined;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parseEmptyLine();
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parseEmptyLine();
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        if (input.length > peg$currPos) {
          s3 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e62); }
        }
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = undefined;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseTextLine() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseLineCharacter();
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseLineCharacter();
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseLineTerminatorSequence();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f103(s1);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parse__() {
    var s0, s1;

    s0 = [];
    s1 = peg$parseWhiteSpace();
    while (s1 !== peg$FAILED) {
      s0.push(s1);
      s1 = peg$parseWhiteSpace();
    }

    return s0;
  }

  function peg$parseEOB() {
    var s0, s1, s2, s3;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseEOL();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseEOL();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseEOL();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e63); }
    }

    return s0;
  }

  function peg$parseEOL() {
    var s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parse__();
    s2 = peg$parseLineTerminatorSequence();
    if (s2 !== peg$FAILED) {
      s1 = [s1, s2];
      s0 = s1;
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e64); }
    }

    return s0;
  }

  function peg$parseEOF() {
    var s0, s1;

    s0 = peg$currPos;
    peg$silentFails++;
    if (input.length > peg$currPos) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e62); }
    }
    peg$silentFails--;
    if (s1 === peg$FAILED) {
      s0 = undefined;
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIndent() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 9) {
      s0 = peg$c51;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e65); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c52) {
        s0 = peg$c52;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e66); }
      }
    }

    return s0;
  }

  function peg$parseWhiteSpace() {
    var s0, s1;

    peg$silentFails++;
    s0 = input.charAt(peg$currPos);
    if (peg$r11.test(s0)) {
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e68); }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e67); }
    }

    return s0;
  }

  function peg$parseLineTerminator() {
    var s0;

    s0 = input.charAt(peg$currPos);
    if (peg$r12.test(s0)) {
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e69); }
    }

    return s0;
  }

  function peg$parseLineTerminatorSequence() {
    var s0, s1;

    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 10) {
      s0 = peg$c53;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e71); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c54) {
        s0 = peg$c54;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e72); }
      }
      if (s0 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 13) {
          s0 = peg$c55;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e73); }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e70); }
    }

    return s0;
  }

  function peg$parseLineCharacter() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parseLineTerminator();
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      if (input.length > peg$currPos) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e62); }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f104(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }


  var result = {
    definition: null,
    sections: [],
    fields: {},
    placeholders: [],
    conditions: {},
    //typePositions: [],
    log: ""
  }
  
  var nextConditionId=0;
  
  addSection(0, 0, {offset:0, level:0, title:'', type:'ROOT', directives:[]});
  
  function addSection(tokenOffset, tokenLength, section){

    if(currentSection() && section.level > currentSection().level+1){
      var virtualSection = {
        offset: section.offset,
        level: section.level-1,
        title: '',
        type: 'VIRTUAL',
        directives: []
      }
      addSection(tokenOffset, 0, virtualSection);
    }
  
    // close previous section:
    if(section.type !== "ROOT"){
      var previousSection = currentSection();
      previousSection.length = tokenOffset - previousSection.offset;
    }
  
    var newSection = {
      offset: tokenOffset,
      offsetExcludeDirectives: section.offset,
      offsetExcludeTitle: tokenOffset+tokenLength,
      length: null,
      lengthIncludeSubsections: null,
   
      level: section.level,
      title: section.title,
      type: section.type,
      style: {},
      tags: {},
      
      placeholders: [],
      paragraphs: []
    };
    
    applyDirectives(newSection, section.directives);
    newSection.index = result.sections.length;
    result.sections.push(newSection);
    addNextParagraph();
  }
  
  function currentSection(){
    return result.sections[result.sections.length-1];
  }
  
  function addNextParagraph(){
    var newParagraph = {
      offset: null,
      length: null,
      hasText: false,
      hasPlaceholders: false,
      placeholders: [],
      style: {}
    };
    
    currentSection().paragraphs.push(newParagraph);
  }
  
  function completeCurrentParagraph(tokenOffset, tokenLength, offsetExcludeDirectives, directives){
    if(currentParagraph() && !currentParagraph().length){
      currentParagraph().offset = tokenOffset;
      currentParagraph().offsetExcludeDirectives = offsetExcludeDirectives;
      currentParagraph().length = tokenLength;
      
      applyDirectives(currentParagraph(), directives)
    }
  }
  
  function currentParagraph(){
    if(currentSection() && currentSection().paragraphs.length > 0)
      return currentSection().paragraphs[currentSection().paragraphs.length-1];
    else
      return undefined;
  }
  
  function getField(fieldName){
    
    if(!result.fields[fieldName]){
      var newField = {
        name: fieldName,
        cardinality: null,
        defaultValue: null,
        description: null,
        filter: null,
        required: false,
        type: null,
        subType: null
      };
      
      result.fields[fieldName] = newField;
      addTypeDefinitionToField(fieldName, {});
    }
    
    return result.fields[fieldName];
  }
  
  
  function addPlaceholder(tokenOffset, tokenLength, directives, fieldName, label){
    var field = null;
    
    if(fieldName) {
      // var newPosition = {
      //   offset: tokenOffset,
      //   length: tokenLength,
      //   label: label,
      //   control: null,
      //   style: {}
      // } 
     
      field = getField(fieldName);
      //applyDirectives(newPosition, directives);    
      //field.positions.push(newPosition);
    }

    var newPlaceholder = {
      field: field,
      offset: tokenOffset,
      length: tokenLength,
      label: label,
      control: null,
      style: {}
    }
    
    if(directives)
      applyDirectives(newPlaceholder, directives);
    
    newPlaceholder.index = result.placeholders.length;
    result.placeholders.push(newPlaceholder);
    currentSection().placeholders.push(newPlaceholder);
    currentParagraph().placeholders.push(newPlaceholder);
    currentParagraph().hasPlaceholders = true;
  }
  
  function applyDirectives(item, directives){
    directives.forEach(function(directive){      
      // convert convenience negations of readonly and hide
      if((directive.type === "STYLE") && (directive.condition)){
      	if(directive.params[0] === "show"){
        	directive.params[0] = "hidden";
            directive.condition = "!(" + directive.condition + ")";
        }
        else if(directive.params[0] === "editable"){
        	directive.params[0] = "readonly";
            directive.condition = "!(" + directive.condition + ")";
        }
      }
      
      // hide is now also allowed as an alias for hidden
      if((directive.type === "STYLE") && (directive.params[0] === "hide")){
      	directive.params[0] === "hidden"
      }
    
      var condition;
      
      if(directive.condition){
        var cid = 'cond' + nextConditionId++;
        
        condition = {
          id: cid,
          expression: directive.condition
        }
        result.conditions[cid] = directive.condition;
      }
    
      if(directive.type === "CONTROL"){
        var newControl = {
          namespace: directive.controlNamespace,
          name: directive.controlName,
          fullName: directive.controlFullName,
          parameters: {}
        };
        
        if(condition)
          newControl.condition = condition;
        
        directive.params.forEach(function(param){
          var newParam = {
            value: param.value
          }
          
          if(param.field)
            newParam.field = param.field;
            
          newControl.parameters[param.name] = newParam;
        });
        
        item.control = newControl;
      }
      else if(directive.type === "STYLE"){
        if(!item.style){
          item.style = {};
        }
        
        directive.params.forEach(function(param){
          var propertyName = getPropertyNameForStyle(item.style, param)
        
          item.style[propertyName] = { value: param.value ?? true };
          
          if(condition)
            item.style[propertyName].condition = condition;
        });
      } else if (directive.type === 'TAGS') {
        const tags = item.tags ?? {}
        item.tags = { ...tags, ...directive.tags };
      }
    });
  }
  
  function getPropertyNameForStyle(item, param){
  	let counter = 1;
    let paramName = param.name ?? param;
    var propertyName = paramName;
    
    while(item.hasOwnProperty(propertyName)){
      propertyName = paramName + '-' + counter++; 
    }
    
    return propertyName;
  }
  
  function addTypeDefinition(tokenOffset, tokenLength, fieldName, typeDefinition){
    addTypeDefinitionToField(fieldName, typeDefinition);
    
    var newTypePosition = {
      offset: tokenOffset,
      length: tokenLength
    };
    
    result.placeholders.push(newTypePosition)
    currentSection().placeholders.push(newTypePosition);
  }
  
  function addTypeDefinitionToField(fieldName, typeDefinition){
    var field = getField(fieldName);

    if( !field.typeDefinition
     || (field.typeDefinition.typeReference && typeDefinition.typeReference && (field.typeDefinition.typeReference != typeDefinition.typeReference))
     || ((field.typeDefinition.cardinality||{}).min != (typeDefinition.cardinality||{}).min)
     || ((field.typeDefinition.cardinality||{}).max != (typeDefinition.cardinality||{}).max)
     || (field.typeDefinition.required != typeDefinition.required ))
    {
      // completely reset the typeDefinition if the typeReference or cardinality differs from
      // former specification
      // TODO: deep-check tags array
      field.typeDefinition = {}
    }
   
    if(typeDefinition.typeReference){
      field.typeDefinition.typeReference = typeDefinition.typeReference;

      if(typeDefinition.typeReference === 'QUICKSYNTAXTYPE'){
        field.typeDefinition.quickSyntaxText = typeDefinition.quickSyntaxText;
      }
    }
    
    field.typeDefinition.help = typeDefinition.help
    field.typeDefinition.required = typeDefinition.required
    field.typeDefinition.cardinality = typeDefinition.cardinality;
    field.tags = typeDefinition.tags || {};
    
    if(typeDefinition.defaultDirectives){
      
      if(!field.typeDefinition.defaultDirectives){
        field.typeDefinition.defaultDirectives = {};
      }
      
      if(typeDefinition.defaultDirectives.control){
        field.typeDefinition.defaultDirectives.control = typeDefinition.defaultDirectives.control;
      }
      
      if(typeDefinition.defaultDirectives.style){
        if(!field.typeDefinition.defaultDirectives.style){
          field.typeDefinition.defaultDirectives.style = {};
        }
      
        for(var styleName in typeDefinition.defaultDirectives.style){
          field.typeDefinition.defaultDirectives.style[styleName] = typeDefinition.defaultDirectives.style[styleName];
        }
      }
      //field.typeDefinition.defaultDirectives = typeDefinition.defaultDirectives;
    }
       
    if(typeDefinition.settingsText){
      if(!field.typeDefinition.settingsText){
        field.typeDefinition.settingsText = {};
      }
      
      // override settings on a per-label basis 
      for(var label in typeDefinition.settingsText){
        field.typeDefinition.settingsText[label] = typeDefinition.settingsText[label].trim();
      }
    }
  }
  
  function completeSections(){

    var allSections = result.sections;
    
    //
    // gather subsections...
    //
    for(var i=0; i<allSections.length; i++){
  
      var section = allSections[i];
      
      section.directSubsections = [];
      section.allSubsections = [];
  
      var inSection = false;
  
      for(var j=0; j<allSections.length; j++){
        var s = allSections[j];
  
        if(s === section)
          inSection = true;
        else if(inSection && s.level <= section.level)
          inSection = false;
  
        if(inSection && (s.level === section.level+1)){
          section.directSubsections.push(s);
          s.parentSectionIndex = section.index
        }
  
        if(inSection && (s.level > section.level))
          section.allSubsections.push(s);
      }
      
      //
      // remove empty paragraphs...
      //
      var lastParagraph = section.paragraphs[section.paragraphs.length-1];
      
      if(!lastParagraph.offset && !lastParagraph.length)
        section.paragraphs.pop();
        
      //
      // set section lengths
      //
      if(i+1 < allSections.length){
        section.length = allSections[i+1].offset - section.offset;
      }
      else{
        section.length = result.definition.length - section.offset;
      }
    }
    
    //
    // set section lengths including subsections
    // (based on previously set section lengths)
    //
    for(var i=0; i<allSections.length; i++){
    
      var section = allSections[i];
    
      if(section.allSubsections.length > 0){
        var lastSubSection = section.allSubsections[section.allSubsections.length-1];
        section.lengthIncludeSubsections = lastSubSection.offset - section.offset + lastSubSection.length;
      } 
      else{
        section.lengthIncludeSubsections = section.length;
      }  
    }
  }

  function applyDefaultDirectives(){
    
    result.placeholders.forEach(function(placeholder){
      if(placeholder.field && placeholder.field.typeDefinition && placeholder.field.typeDefinition.defaultDirectives)
        var defaultDirectives = placeholder.field.typeDefinition.defaultDirectives;
      else
        return;

      if(!placeholder.control && defaultDirectives.control){
        placeholder.control = defaultDirectives.control;
      }

      if(defaultDirectives.style){  
        if(!placeholder.style){
          placeholder.style = defaultDirectives.style;
        }
        else{
          for(var styleName in defaultDirectives.style){
            if(!placeholder.style[styleName]){
              placeholder.style[styleName] = defaultDirectives.style[styleName];
            }
          }
        }
      }
    });
  }
  
  function log(text){
    result.log += text + "\n";
  }

  peg$result = peg$startRuleFunction();

  if (options.peg$library) {
    return /** @type {any} */ ({
      peg$result,
      peg$currPos,
      peg$FAILED,
      peg$maxFailExpected,
      peg$maxFailPos
    });
  }
  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

const peg$allowedStartRules = [
  "Start"
];

export {
  peg$allowedStartRules as StartRules,
  peg$SyntaxError as SyntaxError,
  peg$parse as parse
};
